import React from 'react';
import classNames from 'classnames';

import { Button, IconButton } from 'Permafrost/index';

import { StyledTag } from './Tag.styles';

type Props = {
  disabled?: boolean;
  selected?: boolean;
  value: string;
  onClick?: () => void;
  onRemove: () => void;
};

export const Tag = ({ disabled, selected, value, onClick, onRemove }: Props) => {
  return (
    <StyledTag className={classNames('Tag--container', { selected })}>
      {onClick ? (
        <Button className="value-select" onClick={onClick}>
          {value}
        </Button>
      ) : (
        <>{value}</>
      )}
      <IconButton
        isDisabled={disabled}
        className="remove-btn"
        iconName="x-close"
        onPress={onRemove}
      />
    </StyledTag>
  );
};
