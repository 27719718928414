import styled from 'styled-components';

import ReactSelect from 'react-select';

import { COLORS } from 'Permafrost/index';

import { basicDefaults } from '../commonStyles';

const spacingCommon = '10px';

export const StyledMultiCombobox = styled(ReactSelect)`
  ${basicDefaults};

  background-color: ${COLORS.clay};

  .combobox__control,
  .combobox__menu {
    background-color: inherit;
    border: 1px solid ${COLORS.comet};
    border-radius: 4px;
  }

  // overall component container
  .combobox__control {
    :hover {
      border-color: ${COLORS.baliHai};
    }
  }

  .combobox__control--is-disabled {
    :hover {
      border-color: ${COLORS.comet};
    }
  }

  // the dropdown list container
  .combobox__menu {
    margin-top: 3px;
  }

  // text input placeholder text
  .combobox__placeholder {
    margin-left: calc(${spacingCommon} + 2px); // ensures text doesn’t overlap with input cursor
    color: ${COLORS.lightFontColor};
  }

  // container component for the text input
  .combobox__input-container {
    margin-left: ${spacingCommon};
  }

  // container for selected items (visually inside input)
  .combobox__value-container {
    padding: 0;
    border-radius: inherit;
  }

  // each selected item (pill)
  .combobox__multi-value {
    height: 30px;

    color: ${COLORS.defaultFontColor};
    background-color: ${COLORS.oxfordBlue};
    border-radius: 2px;

    > * {
      color: inherit;
      margin-top: auto;
      margin-bottom: auto;
    }

    &__remove {
      padding-right: ${spacingCommon};

      :hover {
        cursor: pointer;
        color: ${COLORS.white};
        background-color: transparent;
      }
    }

    &__label {
      padding-left: ${spacingCommon};
    }
  }

  // caret button to open/close menu
  .combobox__dropdown-indicator {
    border-radius: inherit;
  }
`;
