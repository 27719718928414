import React from 'react';

import { Pill, PillCheckGroupBase } from '../PillCheckGroupBase';
import { PermafrostComponent, PillProps } from 'Permafrost/types';

type Props = PermafrostComponent & {
  ariaLabel: string;
  enforceSingleValue?: boolean;
  disableGroup?: boolean;
  label?: string;
  onChangeHandler: (incomingValues: string[]) => void;
  pills: PillProps[];
  values: string[];
  verticalOrientation?: boolean;
};

/**
 * Renders a group of 'pill checkboxes'.
 *
 * A group label must be included: either pass a string or markup into the
 * `label` prop, or include an `aria-label` or `aria-labelledby` attribute.
 */
export function PillCheckGroup(props: Props) {
  const {
    ariaLabel,
    enforceSingleValue,
    disableGroup,
    label,
    onChangeHandler,
    pills,
    values,
    verticalOrientation,
  } = props;
  return (
    <PillCheckGroupBase
      aria-label={ariaLabel}
      className="PillCheckGroup"
      enforceSingleValue={enforceSingleValue}
      data-testid={props['data-testid']}
      disableGroup={disableGroup}
      label={label}
      values={values}
      onChange={(incomingValues: string[]) => onChangeHandler(incomingValues)}
      verticalOrientation={verticalOrientation}
    >
      {pills.map((pill: PillProps) => {
        const {
          id,
          caption,
          captionColor,
          fixedWidth,
          isDisabled,
          pillText,
          pillTextColor,
          tooltip,
          value,
        } = pill;
        return (
          <Pill
            id={id}
            key={id}
            caption={caption}
            captionColor={captionColor}
            data-cy={pill['data-cy']}
            data-testid={pill['data-testid']}
            fixedWidth={fixedWidth}
            isDisabled={isDisabled}
            isSelected={values.includes(value)}
            pillTextColor={pillTextColor}
            tooltip={tooltip}
            value={value}
          >
            {pillText}
          </Pill>
        );
      })}
    </PillCheckGroupBase>
  );
}
