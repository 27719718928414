import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import isNull from 'lodash/isNull';

import { AppCrash } from '@indico-data/permafrost';

// App
import { PageContainer } from 'root/components/PageContainer';
import * as ROUTES from 'root/constants/router';
import { useAppStore } from './store/appStore';
import withToast from 'root/components/messaging/ToastsList/withToast';

// Routing
import PrivateRoute from 'root/components/routing/PrivateRoute/PrivateRoute';
import AppRoute from 'root/components/routing/AppRoute/AppRoute';

// Retry Route Import When Initial Attempt Fails
const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

// // Authentication Routes
const LoginView = lazy(() => retry(() => import('Auth/pages/LoginView/LoginView')));
const FourOhFour = lazy(() => retry(() => import('root/pages/FourOhFour/FourOhFour')));
const NoAccess = lazy(() => retry(() => import('root/pages/NoAccess/NoAccess')));

const ProfileView = lazy(() => retry(() => import('Auth/pages/ProfileView/ProfileView')));
const UpdatePasswordView = lazy(() =>
  retry(() => import('Auth/pages/UpdatePasswordView/UpdatePasswordView'))
);
const ConfirmAccountView = lazy(() =>
  retry(() => import('Auth/pages/ConfirmAccountView/ConfirmAccountView'))
);
const ForgotView = lazy(() => retry(() => import('Auth/pages/ForgotView/ForgotView')));
const RegisterView = lazy(() => retry(() => import('Auth/pages/RegisterView/RegisterView')));
const LogoutView = lazy(() => retry(() => import('Auth/pages/LogoutView/LogoutView')));
const AdminView = lazy(() => retry(() => import('Admin/pages/AdminView/AdminView')));

// Datasets Routes
const Datasets = lazy(() => retry(() => import('Sharknado/pages/Datasets/Datasets')));
const DatasetDetails = lazy(() =>
  retry(() => import('Sharknado/pages/DatasetDetails/DatasetDetails'))
);
const NewDataset = lazy(() => retry(() => import('Sharknado/pages/NewDataset/NewDataset')));
const ExamplesList = lazy(() => retry(() => import('Sharknado/pages/ExamplesList/ExamplesList')));

// Crowdlabel Routes
const Questionnaires = lazy(() =>
  retry(() => import('Crowdlabel/pages/QuestionnairesView/QuestionnairesView'))
);
const QuestionnaireDetails = lazy(() =>
  retry(() => import('Crowdlabel/pages/QuestionnaireDetails/QuestionnaireDetails'))
);
const LabelingInterface = lazy(() =>
  retry(() => import('Crowdlabel/pages/LabelingInterface/LabelingInterface'))
);
const SingleExampleLabeling = lazy(() =>
  retry(() => import('Crowdlabel/pages/SingleExampleLabeling/SingleExampleLabeling'))
);

// Moonbow Routes
const DatasetsAndModels = lazy(() =>
  retry(() => import('Moonbow/pages/DatasetsAndModels/DatasetsAndModels'))
);
const ModelDetails = lazy(() => retry(() => import('Moonbow/pages/ModelDetails/ModelDetails')));

// Sunbow Routes
const SunbowList = lazy(() => retry(() => import('Sunbow/pages/SunbowList/SunbowList')));
const ReviewQueue = lazy(() => retry(() => import('Sunbow/pages/ReviewQueue/ReviewQueue')));
const ExceptionsQueue = lazy(() =>
  retry(() => import('Sunbow/pages/ExceptionsQueue/ExceptionsQueue'))
);

const SingleReviewQueue = lazy(() =>
  retry(() => import('Sunbow/pages/SingleReviewQueue/SingleReviewQueue'))
);
const SingleExceptionsQueue = lazy(() =>
  retry(() => import('Sunbow/pages/SingleExceptionsQueue/SingleExceptionsQueue'))
);
const SingleQueueSuccess = lazy(() =>
  retry(() => import('Sunbow/pages/SingleQueueSuccess/SingleQueueSuccess'))
);

// Cloudburst Routes
const WorkflowsList = lazy(() =>
  retry(() => import('Cloudburst/pages/WorkflowsList/WorkflowsList'))
);
const WorkflowDetails = lazy(() =>
  retry(() => import('Cloudburst/pages/WorkflowDetails/WorkflowDetails'))
);
const WorkflowNew = lazy(() =>
  retry(() => import('Cloudburst/pages/CreateNewWorkflow/CreateNewWorkflow'))
);
const WorkflowSubmissionsList = lazy(() =>
  retry(() => import('Cloudburst/pages/SubmissionsList/SubmissionsList'))
);

// Zephyr Routes
const GalleryPage = lazy(() => retry(() => import('Zephyr/pages/Gallery/Gallery')));

const GalleryNew = lazy(() =>
  retry(() =>
    import(
      // TODO: probably want to move this file into pages dir like the above...
      'Zephyr/features/system-blueprints/new-acord-workflow/components/CreateNewWorkflow/CreateNewWorkflow'
    )
  )
);

const routeMap = [
  { path: ROUTES.AUTH_ACCOUNT, component: ProfileView, type: 'PRIVATE' },
  { path: ROUTES.AUTH_CHANGE_PASSWORD, component: UpdatePasswordView, type: 'REGULAR' },
  { path: ROUTES.AUTH_CONFIRM_ACCOUNT, component: ConfirmAccountView, type: 'REGULAR' },
  { path: ROUTES.AUTH_FORGOT, component: ForgotView, type: 'REGULAR' },
  { path: ROUTES.AUTH_REGISTER, component: RegisterView, type: 'REGULAR' },
  { path: ROUTES.AUTH_LOGOUT, component: LogoutView, type: 'REGULAR' },

  { path: ROUTES.BASE_ADMIN, component: AdminView, type: 'PRIVATE' },
  { path: ROUTES.ADMIN_REPORTS, component: AdminView, type: 'PRIVATE' },

  { path: ROUTES.BASE_DATASETS, component: Datasets, type: 'PRIVATE' },
  { path: ROUTES.DATASET_NEW, component: NewDataset, type: 'PRIVATE' },
  { path: ROUTES.DATASETS_DETAILS, component: DatasetDetails, type: 'PRIVATE' },
  { path: ROUTES.DATASETS_EXAMPLES_LIST, component: ExamplesList, type: 'PRIVATE' },

  { path: ROUTES.BASE_CROWDLABEL, component: Questionnaires, type: 'PRIVATE' },
  { path: ROUTES.CROWDLABEL_TASKS_DETAILS, component: QuestionnaireDetails, type: 'PRIVATE' },
  { path: ROUTES.CROWDLABEL_LABELING, component: LabelingInterface, type: 'PRIVATE' },
  { path: ROUTES.CROWDLABEL_LABELING_SINGLE, component: SingleExampleLabeling, type: 'PRIVATE' },

  { path: ROUTES.BASE_MOONBOW, component: DatasetsAndModels, type: 'PRIVATE' },
  { path: ROUTES.MOONBOW_DETAILS, component: ModelDetails, type: 'PRIVATE' },

  { path: ROUTES.BASE_SUNBOW, component: SunbowList, type: 'PRIVATE' },
  { path: ROUTES.REVIEW_QUEUE, component: ReviewQueue, type: 'PRIVATE' },
  { path: ROUTES.EXCEPTIONS_QUEUE, component: ExceptionsQueue, type: 'PRIVATE' },
  { path: ROUTES.SINGLE_REVIEW_QUEUE, component: SingleReviewQueue, type: 'PRIVATE' },
  { path: ROUTES.SINGLE_EXCEPTIONS_QUEUE, component: SingleExceptionsQueue, type: 'PRIVATE' },
  { path: ROUTES.SINGLE_SUNBOW_QUEUE_SUCCESS, component: SingleQueueSuccess, type: 'PRIVATE' },

  { path: ROUTES.BASE_CLOUDBURST, component: WorkflowsList, type: 'PRIVATE' },
  { path: ROUTES.WORKFLOW_DETAILS, component: WorkflowDetails, type: 'PRIVATE' },
  { path: ROUTES.WORKFLOW_SUBMISSIONS_LIST, component: WorkflowSubmissionsList, type: 'PRIVATE' },
  { path: ROUTES.GALLERY_DETAILS, component: GalleryPage, type: 'PRIVATE' },
  { path: ROUTES.GALLERY_ADD_NEW, component: GalleryNew, type: 'PRIVATE' },
  { path: ROUTES.WORKFLOW_ADD_NEW, component: WorkflowNew, type: 'PRIVATE' },
  { path: ROUTES.BASE_NO_ACCESS, component: NoAccess, type: 'REGULAR' },

  { path: '*', component: FourOhFour, type: 'REGULAR' },
];

const appStoreSelector = (state) => ({
  currentLocation: state.currentLocation,
  setLocation: state.setLocation,
  routeError: state.routeError,
  setRouteError: state.setRouteError,
});

const MainRouter = (props) => {
  const { error, loginError, addToast } = props;
  const [redirect, setRedirect] = useState({
    pathname: '',
    search: '',
  });

  const location = useLocation();
  const { currentLocation, setLocation, routeError, setRouteError } = useAppStore(appStoreSelector);

  // Update zustand store with current and previous locations.
  // If routeError then display toast and clear the routeError state.
  useEffect(() => {
    if (location?.pathname !== currentLocation?.pathname) {
      setLocation(currentLocation, location); // sets previous to current and current to new
    }
    if (routeError) {
      addToast(routeError, 'error');
      setRouteError('');
    }
  }, [location]);

  const resetRedirect = (location) => {
    if (location) {
      setRedirect({
        pathname: location.pathname,
        search: location.search,
      });
    } else {
      setRedirect({
        pathname: '',
        search: '',
      });
    }
  };

  return (
    <div className="main-content" style={{ height: '100%' }}>
      {!isNull(error) ? (
        <AppCrash />
      ) : (
        <Suspense fallback={<PageContainer />}>
          <Switch>
            <AppRoute exact path="/">
              <LoginView redirect={redirect} />
            </AppRoute>
            <AppRoute exact path={ROUTES.BASE_AUTH}>
              <LoginView redirect={redirect} />
            </AppRoute>
            {routeMap.map((route, i) => {
              if (route.type === 'PRIVATE') {
                return (
                  <PrivateRoute
                    exact
                    key={i}
                    path={route.path}
                    component={route.component}
                    loginError={loginError}
                    redirect={redirect}
                    resetRedirect={resetRedirect}
                  />
                );
              } else {
                return <AppRoute exact key={i} path={route.path} component={route.component} />;
              }
            })}
          </Switch>
        </Suspense>
      )}
    </div>
  );
};

export const MainRouterWithToast = withToast(MainRouter);
