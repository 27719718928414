import styled from 'styled-components';

export const StyledAccordion = styled.details`
  summary {
    display: inherit;
    padding: 0 12px;
    cursor: pointer;
  }

  .header-container,
  h2 {
    display: flex;
  }

  .toggle-icon {
    height: 24px;
    margin: 6px 0;
    border-radius: 50%;
    transition: 0.3s transform ease-in-out;
  }

  h2 {
    align-items: center;
    margin: 0 0 0 8px;
    font-size: 22px;
  }

  .content {
    margin: 10px 0 10px 20px;
  }

  &[open] > summary .toggle-icon {
    transform: rotate(180deg);
  }
`;
