import styled, { keyframes } from 'styled-components';

import { COLORS, TYPOGRAPHY } from 'Permafrost/index';

const ripple = (size: string) => keyframes`
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  
  20% {
    opacity: 1;
  }
  
  100% {
    width: ${size};
    height: ${size};
    opacity: 0;
  }
`;

const animationSpeed = 1.4;

export const StyledLoadingIndicator = styled.div<{
  overallSize: string;
  rippleSize: string;
}>`
  display: grid;
  place-items: center;

  width: ${(props) => props.overallSize};
  height: ${(props) => props.overallSize};

  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: '';
    border: 4px solid currentColor;
    border-radius: 50%;

    grid-area: 1 / 1;

    animation: ${(props) => ripple(props.rippleSize)} ${animationSpeed}s
      cubic-bezier(0, 0.2, 0.8, 1) infinite;

    @media (prefers-reduced-motion) {
      animation-duration: ${animationSpeed * 2}s;
    }
  }

  &:after {
    animation-delay: -${animationSpeed / 2}s;

    @media (prefers-reduced-motion) {
      animation-delay: -${animationSpeed}s;
    }
  }
`;

export const StyledMessageLoadingIndicator = styled.div<{ overallSize: string }>`
  display: block;

  > * {
    margin-left: auto;
    margin-right: auto;
  }

  .message {
    text-align: center;
    display: block;
    width: ${(props) => props.overallSize};
    margin-top: -50px;

    color: ${COLORS.lightFontColor};
    font-size: ${TYPOGRAPHY.fontSize.base};
    font-style: italic;
  }
`;
