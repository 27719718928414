import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

export const StyledListControls = styled.div`
  height: 36px;

  display: flex;

  .confirm-delete-container {
    height: inherit;
    display: inherit;
    align-items: center;

    .button-confirm-delete,
    .button-cancel-delete {
      color: white;
      box-shadow: none;
    }

    .button-confirm-delete {
      margin-left: 16px;
      background-color: #1777c2;
    }

    .button-cancel-delete {
      margin-left: 8px;
      background-color: ${COLORS.red};
    }
  }

  .move-buttons-container {
    display: flex;
  }

  .list-buttons {
    display: inherit;
    margin-left: 10px;

    Button {
      padding: 0;
      background: none;
      box-shadow: none;
      color: ${COLORS.defaultFontColor};

      &:disabled {
        opacity: 0.2;

        :hover {
          background-color: transparent;
        }
      }
    }
  }

  .button-move-down {
    margin-left: 8px;
  }

  .button-move-up {
    margin-left: 12px;
  }

  .edit {
    margin-left: 18px;
  }
`;
