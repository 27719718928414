import React, { createContext, useContext, useRef, useState } from 'react';

import classNames from 'classnames';

import { AriaRadioGroupProps, AriaRadioProps } from '@react-types/radio';
import { useRadio, useRadioGroup } from '@react-aria/radio';
import { useFocusRing } from '@react-aria/focus';
import { useVisuallyHidden } from '@react-aria/visually-hidden';

import { PermafrostComponent } from 'Permafrost/types';

import { StyledRadioGroup } from './RadioGroup.styles';

type RadioGroupState = {
  isDisabled: boolean;
  isReadOnly: boolean;
  lastFocusedValue: string | null;
  selectedValue: string | null;
  setLastFocusedValue(value: string): void;
  setSelectedValue(value: string): void;
};

export type RadioGroupProps = PermafrostComponent & {
  value: string;
  onChange: (value: string) => void;
  children: React.ReactNode;
  className?: string;
} & AriaRadioGroupProps;

export const RadioContext = createContext<{ isDisabled?: boolean } & Partial<RadioGroupState>>({});

/**
 * Renders a group of radio buttons with no styling. This component is not
 * designed to be user-facing, and should only be used to provide functionality.
 *
 * A group label must be included: either pass a string or markup into the
 * `label` prop, or include an `aria-label` or `aria-labelledby` attribute.
 */
export function RadioGroup(props: RadioGroupProps) {
  const {
    children,
    className,
    value,
    id,
    isDisabled,
    isReadOnly,
    label,
    name,
    onChange,
    orientation,
  } = props;

  const [lastFocusedValue, setLastFocusedValue] = useState<string | null>(null);

  const radioGroupState = {
    isDisabled: isDisabled || false,
    isReadOnly: isReadOnly || false,
    lastFocusedValue,
    name: name || '',
    selectedValue: value,
    setLastFocusedValue,
    setSelectedValue: onChange,
  };

  const { radioGroupProps, labelProps } = useRadioGroup(props, radioGroupState);

  return (
    <StyledRadioGroup
      {...radioGroupProps}
      className={classNames(className, { horizontal: orientation === 'horizontal' })}
      data-cy={props['data-cy']}
      id={id}
    >
      {label && (
        <div className="groupLabel" {...labelProps}>
          {label}
        </div>
      )}

      <RadioContext.Provider value={{ ...radioGroupState, isDisabled }}>
        <>{children}</>
      </RadioContext.Provider>
    </StyledRadioGroup>
  );
}

/**
 * A single radio button and its label; no styling is applied, and the native
 * radio button is visually hidden.
 */
export function Radio(
  props: AriaRadioProps & {
    className?: string;
    isVisuallySelected?: (selectedValue: string) => void;
  }
) {
  const { children, className, isDisabled, isVisuallySelected } = props;
  const state = useContext(RadioContext);
  const inputRef = useRef<HTMLInputElement>(null);

  const { visuallyHiddenProps } = useVisuallyHidden();

  const { inputProps } = useRadio(props, state as any, inputRef);
  const { isFocusVisible, focusProps } = useFocusRing();

  let isSelected;

  if (isVisuallySelected) {
    isSelected = isVisuallySelected(state.selectedValue as string);
  } else {
    isSelected = state.selectedValue === props.value;
  }

  return (
    <label
      className={classNames(className, {
        disabled: isDisabled || state.isDisabled,
        selected: isSelected,
        focused: isFocusVisible,
      })}
    >
      <input {...inputProps} {...visuallyHiddenProps} {...focusProps} ref={inputRef} />

      {children}
    </label>
  );
}
