import React from 'react';
import { render } from 'react-dom';

if (module.hot) {
  module.hot.accept();
}

import { Root } from './Root';

render(<Root />, document.getElementById('root'));
