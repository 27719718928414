import React from 'react';

import { Icon } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledAccordion } from './Accordion.styles';

type Props = PermafrostComponent & {
  content: React.ReactNode;
  header?: React.ReactNode | string;
  open?: boolean; //only affects default state - accordion default is closed, can pass open=true to set default as open
};

export const Accordion = (props: Props) => {
  const { className, content, header, id, open } = props;

  return (
    <StyledAccordion className={className} id={id} open={open} data-cy={props['data-cy']}>
      <summary>
        <div className="header-container">
          <Icon className="toggle-icon" name="chevron-up" size={[14]} />
          {header ? <h2>{header}</h2> : null}
        </div>
      </summary>
      <div className="content">{content}</div>
    </StyledAccordion>
  );
};
