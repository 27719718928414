/**
 * @param obj
 * @param converter
 * @returns The same object with all keys converted with `convert`
 */
export function convertKeys<T>(obj: T, converter: (str?: string) => string): T {
  if (Array.isArray(obj)) {
    return (obj.map((item) => convertKeys(item, converter)) as unknown) as T;
  } else if (obj !== null && typeof obj === 'object') {
    return (Object.keys(obj).reduce((acc: { [key: string]: any }, key: string) => {
      const newKey = converter(key);
      // @ts-ignore
      acc[newKey] = convertKeys(obj[key], converter);
      return acc;
    }, {}) as unknown) as T;
  }
  return obj;
}
