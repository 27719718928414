import React, { createContext, useEffect, useState } from 'react';

import { Toast } from '@indico-data/permafrost';

import { TOAST_TIMEOUT } from 'root/constants/app';

import { StyledToastsList } from './ToastsList.styles';

type Props = {
  children: React.ReactChildren;
};

type Toast = {
  display: boolean;
  message: string;
  style: 'success' | 'error';
};

export type AddToast = (message: string, type: 'success' | 'error') => void;

export const ToastsContext = createContext<{
  toasts: Toast[];
  addToast: AddToast;
}>({
  toasts: [],
  addToast: () => {},
});

export function ToastsList(props: Props) {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = (message: string, type: string) => {
    if (toasts.length === 5) {
      return;
    }

    setToasts((prevToasts) => [...prevToasts, { display: true, message, style: type } as Toast]);
  };

  // removes toast after timeout
  useEffect(() => {
    if (toasts.length) {
      setTimeout(() => setToasts((prevToasts) => prevToasts.slice(1)), TOAST_TIMEOUT);
    }
  }, [toasts]);

  return (
    <ToastsContext.Provider value={{ toasts: toasts, addToast }}>
      <>
        <StyledToastsList>
          {toasts.map((toast, index) => (
            <Toast key={`toast${index}`} toast={toast} />
          ))}
        </StyledToastsList>
        {props.children}
      </>
    </ToastsContext.Provider>
  );
}
