import React, { useReducer, useState, useEffect } from 'react';

import { GlobalStyles } from '@indico-data/permafrost';

import ApolloClient from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

import UserContext, { UserContextState, UserReducer } from './store/userContext';

import introspectionQueryResultData from './transformedFragmentTypes.json';

import { envUtils } from '@indico-data/utils';

import { AppContainer } from './AppContainer';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});
const link = ApolloLink.from([
  new RetryLink(),
  new HttpLink({
    uri: `${window.indico.appURLs.FogURL}/api/graphql`,
    credentials: 'include',
  }),
]);
const cache = new InMemoryCache({ fragmentMatcher });

const client = new ApolloClient({
  link,
  cache,
});

export const Root = () => {
  const [completedFetchFeatureConfigs, setCompletedFetchFeatureConfigs] = useState<boolean>(false);

  useEffect(() => {
    async function fetchAndSetFeatureConfigs() {
      await fetch(`${envUtils.getLocalDomain()}/feature_config.json`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('HTTP error ' + response.status);
          }
          return response.json();
        })
        .then((json) => {
          const { features } = json;
          window.indico.featuresConfig = {
            ...window.indico.featuresConfig,
            ...features,
          };
          console.info('feature_config.json fetched successfully');
        })
        .catch(function (err) {
          console.error('Error fetching feature_config.json', err);
        })
        .finally(() => {
          setCompletedFetchFeatureConfigs(true);
        });
    }
    fetchAndSetFeatureConfigs();
  }, []);

  return (
    <>
      <GlobalStyles />
      <UserContext.Provider value={useReducer(UserReducer, UserContextState)}>
        <ApolloProvider client={client}>
          <div className="app-root" style={{ height: '100%' }}>
            {completedFetchFeatureConfigs ? <AppContainer /> : null}
          </div>
        </ApolloProvider>
      </UserContext.Provider>
    </>
  );
};
