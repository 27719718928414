import React from 'react';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Icon, Logo, Button } from '@indico-data/permafrost';

import * as ROUTES from 'root/constants/router';
import { useAppStore, StateAndActions as AppStoreStateAndActions } from 'root/store/appStore';
import { useCurrentUser } from 'root/hooks/authentication';

import { APP_CHROME_MENU } from './menu';
import { StyledAppChrome } from './AppChrome.styles';

type Props = {
  selectedModule: string;
  version: { full: string; abbr: string };
  children: React.ReactNode;
};

const appStoreSelector = (state: AppStoreStateAndActions) => ({
  sidebarOpen: state.sidebarOpen,
  toggleSidebar: state.toggleSidebar,
});

export const AppChrome = (props: Props) => {
  const trialEdition = window.indico?.features?.trialEdition;
  const { sidebarOpen, toggleSidebar } = useAppStore(appStoreSelector);
  const currentUser = useCurrentUser();
  const angle = !sidebarOpen ? 'chevron-right' : 'chevron-left';

  const menu = [[...APP_CHROME_MENU[0]], [...APP_CHROME_MENU[1]]];
  const isAdmin = currentUser?.scopes?.map((s: any) => s.scope).includes('manage_users');
  const isTeam = currentUser?.scopes?.map((s: any) => s.scope).includes('manage_dataset');
  const isDeveloper = currentUser?.scopes?.map((s: any) => s.scope).includes('graphiql'); // Product

  const adminItem = {
    id: 'ADMIN_APP',
    text: 'Admin',
    icon: <Icon name="recruiter" />,
    destination: ROUTES.BASE_ADMIN,
    anchor: false,
  };

  const galleryItem = {
    id: 'ZEPHYR_APP',
    text: 'Gallery',
    icon: <Icon name="gallery" />,
    destination: ROUTES.GET_GALLERY_DETAIL({ detail: 'workflows' }),
    anchor: false,
  };

  const graphiqlItem = {
    id: 'GRAPHIQL_APP',
    text: 'GraphiQL',
    icon: <Icon name="graphiql" />,
    destination: `${get(window, 'indico.appURLs.FogURL')}/api/graphql`,
    anchor: true,
  };

  if (isAdmin) {
    menu[1].splice(1, 0, adminItem);
    menu[1].splice(2, 0, galleryItem);
  }

  if (!isAdmin && isTeam) {
    menu[1].splice(1, 0, galleryItem);
  }

  if (isDeveloper) {
    menu[1].push(graphiqlItem);
  }

  return (
    <StyledAppChrome
      className={classNames({ closed: sidebarOpen === false })}
      data-cy="appChromeSidebar"
    >
      <header className="AppNavigation">
        <div className="AppBranding">
          {sidebarOpen ? (
            <div className="AppLogo AppLogo--Full">
              <Logo />
            </div>
          ) : (
            <Button
              className="AppLogo AppLogo--Small"
              data-cy="appChromeSidebar--openBtn"
              onClick={toggleSidebar}
              variant="no-style"
            >
              <Logo logomark={true} />
            </Button>
          )}

          <Button
            data-cy="appChromeSidebar--closeBtn"
            className="AppChrome--Toggle"
            aria-label="Toggle Sidebar"
            onClick={toggleSidebar}
            variant="no-style"
          >
            <Icon name={angle} size={['17px']} />
          </Button>
        </div>

        <nav>
          {menu.map((s, i) => {
            return (
              <div key={i}>
                <ul className="LinkList">
                  {s.map((m, j) => {
                    if (!m.destination || m.destination === 'undefined') {
                      return null;
                    }
                    if (m.anchor) {
                      return (
                        <li key={j}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={m.destination}
                            title={m.text}
                            data-cy={`appChromeLink--${m.text}`}
                            className={classNames('AppChrome--link', {
                              current: m.id === props.selectedModule,
                            })}
                          >
                            <span className="LinkList-Icon">{m.icon}</span>
                            <span className="LinkList-Text">{m.text}</span>
                          </a>
                        </li>
                      );
                    }
                    return (
                      <li key={j}>
                        <Link
                          to={m.destination}
                          title={m.text}
                          data-cy={`appChromeLink--${m.text}`}
                          className={classNames('AppChrome--link', {
                            current: m.id === props.selectedModule,
                          })}
                        >
                          <span className="LinkList-Icon">{m.icon}</span>
                          <span className="LinkList-Text">{m.text}</span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                {i < menu.length - 1 && <hr />}
              </div>
            );
          })}
        </nav>

        <div className="ipa-version">
          {trialEdition && <span className="trial">{sidebarOpen ? 'Trial Edition' : 'Trial'}</span>}

          <span className="version">{sidebarOpen ? props.version.full : props.version.abbr}</span>
        </div>
      </header>

      <main className="AppContents">{props.children}</main>
    </StyledAppChrome>
  );
};
