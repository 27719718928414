import styled from 'styled-components';

import { TYPOGRAPHY } from 'Permafrost/index';

export const StyledFlag = styled.span<{
  flagColor: string;
  fontColor: string;
}>`
  clip-path: polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%);
  padding: 1px 12px 1px 4px;
  border-radius: 3px 0 0 3px;
  background-color: ${(props) => props.flagColor};
  font-size: ${TYPOGRAPHY.fontSize.base};
  color: ${(props) => props.fontColor};
  font-weight: bold;
  height: fit-content;
`;
