import React, { CSSProperties } from 'react';

import { StyledToast } from './Toast.styles';

type Props = {
  className?: string;
  style?: CSSProperties;
  toast: {
    display: boolean;
    message: string;
    style: 'error' | 'success';
  };
};

export const Toast = (props: Props) => {
  const { className = '', style = {} } = props;

  return (
    <StyledToast
      className={`Toast ${className} ${props.toast.style} ${props.toast.display ? 'show' : 'hide'}`}
      style={style || {}}
    >
      <span data-cy={`toast--${props.toast.style}`}>{props.toast.message}</span>
    </StyledToast>
  );
};
