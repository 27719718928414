import React from 'react';

const indicons = {
  'indico-o-white': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M100 50c0 27.6-22.4 50-50 50S0 77.6 0 50 22.4 0 50 0s50 22.4 50 50zM50 19.4c-16.9 0-30.6 13.7-30.6 30.6S33.1 80.6 50 80.6 80.6 66.9 80.6 50 66.9 19.4 50 19.4zm0 21.7c-4.9 0-8.9 4-8.9 8.9s4 8.9 8.9 8.9 8.9-4 8.9-8.9-4-8.9-8.9-8.9z" />
    </svg>
  ),
  account: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M50 0C22.4 0 0 22.4 0 50s22.4 50 50 50 50-22.4 50-50S77.6 0 50 0zm0 8c23.2 0 42 18.8 42 42 0 9.9-3.4 19-9.2 26.2C75.6 65.8 63.6 59 50 59s-25.6 6.8-32.8 17.2C11.4 69 8 59.9 8 50 8 26.8 26.8 8 50 8zM22.9 82c5.7-9 15.7-15 27.1-15s21.4 6 27.1 15C69.8 88.2 60.3 92 50 92s-19.8-3.8-27.1-10zM50 56c11.6 0 21-9.4 21-21s-9.4-21-21-21-21 9.4-21 21 9.4 21 21 21zm0-34c7.2 0 13 5.8 13 13s-5.8 13-13 13-13-5.8-13-13 5.8-13 13-13z" />
    </svg>
  ),
  address: (
    <svg viewBox="0 0 50 50" fill="currentColor">
      <path d="m24 33.7q5.35-4.3 8.1-8.25t2.75-7.5q0-3.05-1.1-5.175t-2.75-3.475-3.55-1.95-3.45-0.6-3.45 0.6-3.55 1.95-2.75 3.475-1.1 5.175q0 3.55 2.75 7.5t8.1 8.25zm0 2.85q-6.65-5-9.9-9.6t-3.25-9q0-3.35 1.2-5.85t3.075-4.2 4.225-2.55 4.65-0.85 4.65 0.85 4.225 2.55 3.075 4.2 1.2 5.85q0 4.4-3.25 9t-9.9 9.6zm0-15.8q1.3 0 2.225-0.925t0.925-2.225-0.925-2.2-2.225-0.9-2.225 0.925-0.925 2.225 0.925 2.2 2.225 0.9zm-13.15 22.75v-2.25h26.3v2.25zm13.15-25.55z" />
    </svg>
  ),
  'api-doc': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M50 10c22.1 0 40 17.9 40 40S72.1 90 50 90 10 72.1 10 50s17.9-40 40-40m0-10C22.4 0 0 22.4 0 50s22.4 50 50 50 50-22.4 50-50S77.6 0 50 0zm33.3 47.5L69.1 33.3c-1.3-1.3-3.6-1.3-4.9 0-.7.7-1 1.6-1 2.4 0 .8.4 1.8 1 2.4L76 49.9 64.2 61.7c-.7.7-1 1.5-1 2.4 0 .9.4 1.8 1 2.4s1.5 1 2.4 1c.9 0 1.8-.4 2.4-1l14.2-14.2c.7-.7 1-1.5 1-2.4.1-.9-.3-1.8-.9-2.4zM24 49.9l11.8-11.7c1.4-1.4 1.4-3.7 0-5.1-1.3-1.3-3.6-1.3-4.9 0L16.7 47.5c-.7.7-1 1.6-1 2.4s.4 1.8 1 2.4l14.2 14.3c.7.7 1.5 1 2.4 1 .9 0 1.8-.4 2.4-1 .7-.7 1-1.6 1-2.4 0-.9-.4-1.8-1-2.4L24 49.9zM58.7 31c-.4-.8-1.2-1.4-2.2-1.8-1.9-.6-3.8.4-4.3 2.2L41.1 66.5c-.2.9-.2 1.8.2 2.6s1.1 1.4 2 1.7c.4.2.7.2 1.2.2.2 0 .4 0 .7-.1 1.2-.3 2.3-1.2 2.7-2.4l11.4-35c-.1-.9-.1-1.8-.6-2.5z" />
    </svg>
  ),
  'arrow-dots': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M19 10c0 5-4 9-9 9s-9-4-9-9 4-9 9-9 9 4 9 9zm11-9c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm20 0c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zM30 21c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm20 0c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm20 0c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zM50 41c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm20 0c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm20 0c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zM30 61c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm20 0c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm20 0c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zM10 81c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm20 0c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm20 0c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9z" />
    </svg>
  ),
  'arrow-down': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M41.4,69.3L41.4,69.3L41.4,69.3z" />
      <path
        id="arrow"
        d="M7.4,59l39.8,39.7c1.7,1.7,3.8,1.7,5.5,0L92.6,59c0.8-0.8,0.8-1.9,0-2.7l-2.6-2.6c-0.8-0.8-1.9-0.8-2.7,0
        L53.8,87.1V1.9C53.8,0.8,53,0,51.9,0h-3.7c-1.1,0-1.9,0.9-1.9,1.9v85.1L12.7,53.6c-0.8-0.8-1.9-0.8-2.7,0l-2.6,2.6
        C6.6,57,6.7,58.2,7.4,59z"
      />
    </svg>
  ),
  'arrow-up-circle': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M100 50c0 27.6-22.4 50-50 50S0 77.6 0 50 22.4 0 50 0s50 22.4 50 50zm-28.6-6.1L53.3 25.8c-.5-.6-1.5-1.8-3.3-1.8-1.7 0-2.8 1.2-3.4 1.8L28.5 43.9c-1.4 1.4-1.4 3.7 0 5.1 1.4 1.4 3.7 1.4 5.1 0L46 36.6V70c0 2.2 1.8 4 4 4s4-1.8 4-4V36.6L66.3 49c1.4 1.4 3.7 1.4 5.1 0 1.4-1.4 1.4-3.7 0-5.1z" />
    </svg>
  ),
  'arrows-cursor': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M68.8 83.2L53.3 98.6c-1.8 1.8-4.8 1.8-6.6 0L31.2 83.2c-3-3-.9-8 3.3-8h10V55.5H24.8v10c0 4.2-5 6.3-8 3.3L1.4 53.3c-1.8-1.8-1.8-4.8 0-6.6l15.5-15.5c3-3 8-.9 8 3.3v10h19.7V24.8h-10c-4.2 0-6.3-5-3.3-8L46.7 1.4c1.8-1.8 4.8-1.8 6.6 0l15.5 15.5c3 3 .9 8-3.3 8h-10v19.7h19.7v-10c0-4.2 5-6.3 8-3.3l15.5 15.5c1.8 1.8 1.8 4.8 0 6.6L83.2 68.8c-3 3-8 .9-8-3.3v-10H55.5v19.7h10c4.2 0 6.2 5 3.3 8z" />
    </svg>
  ),
  branch: (
    <svg viewBox="0 0 642 514">
      <path
        d="M385 321H257c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32V353c0-17.67-14.33-32-32-32zM193 33c0-17.67-14.33-32-32-32H33C15.33 1 1 15.33 1 33v128c0 17.67 14.33 32 32 32h95.72l73.16 128.04C212.98 301.98 233.4 289 257 289h.28L193 176.51V129h224V65H193V33zM609 1H481c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32V33c0-17.67-14.33-32-32-32z"
        fill="currentColor"
      />
    </svg>
  ),
  'check-circle': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M100 50c0 27.6-22.4 50-50 50S0 77.6 0 50 22.4 0 50 0s50 22.4 50 50zM78 30.7c-.7-1-1.9-1.5-3.5-1.5-1.2 0-2.3.5-3.3 1.5C65 37 45.3 56.6 42.4 59.4L28.8 48c-1-.9-2.2-1.3-3.5-1.2-1.3.1-2.5.7-3.3 1.8-.9 1-1.3 2.2-1.2 3.5.1 1.3.7 2.5 1.8 3.3l16.8 14.2c.9.7 2 1.1 3.1 1.1 1.3 0 2.5-.5 3.4-1.4 6.8-6.6 28.7-28.7 32-32 1.8-1.7 1.8-4.6.1-6.6z" />
    </svg>
  ),
  check: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M96.4 18.5c-1.4-1.6-3.4-2.4-6.3-2.4-2.1 0-4.3 1-6 2.7-10.9 11.1-41.6 41.6-46.7 46.7l-22-18c-1.8-1.6-4.1-2.3-6.4-2.1-2.3.2-4.4 1.3-6 3.1-3.2 3.7-2.8 9.2 1 12.4l27.9 23c1.6 1.3 3.6 2 5.6 2 2.3 0 4.6-.8 6.1-2.5 12-11.8 46.8-46.7 52.6-52.6 3.3-3.2 3.4-8.6.2-12.3z" />
    </svg>
  ),
  checkbox: (
    <svg viewBox="0 0 102 102" fill="currentColor">
      <g transform="translate(1 1)">
        <path
          display={'none'}
          d="m10.3 97.3c-2.8 0-5.1-1-7.1-2.9-1.9-1.9-2.9-4.3-2.9-7.1v-74.5c0-2.8 1-5.2 2.9-7.1s4.3-3 7.1-3h74.5c1.2 0 2.4 0.3 3.7 0.9s2.3 1.2 3 1.9l-7.8 7.8v-0.5h-73.4v74.5h74.5v-42l10.1-10.1v52.1c0 2.8-1 5.1-3 7.1-2 1.9-4.4 2.9-7.1 2.9h-74.5zm35.2-23.4-28-28 5.9-5.9 22.1 22.2 49-49 5.8 5.9-54.8 54.8z"
        />
        <path d="M45.1,74.3L45.1,74.3H45L19.1,48.4l9.7-9.7L45.1,55L90.1,9.9l9.7,9.7L45.1,74.3z M81,51v32.5H13.9V16.4h57L84.5,2.9  c-0.6-0.1-1.2-0.1-1.7-0.1H12.1c-6.6,0-12,5.4-12,12v70.6c0,6.6,5.4,12,12,12h70.6c6.6,0,12-5.4,12-12v-48L81,51z" />
      </g>
    </svg>
  ),
  'chevron-down': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M46.1 78.7L1.6 34.2c-2.1-2.1-2.1-5.6 0-7.8l5.2-5.2c2.1-2.1 5.6-2.1 7.8 0L50 56.5l35.4-35.3c2.1-2.1 5.6-2.1 7.8 0l5.2 5.2c2.1 2.1 2.1 5.6 0 7.8L53.9 78.7c-2.2 2.2-5.6 2.2-7.8 0z" />
    </svg>
  ),
  'chevron-left': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M21.3 46.1L65.8 1.6c2.1-2.1 5.6-2.1 7.8 0l5.2 5.2c2.1 2.1 2.1 5.6 0 7.8L43.5 50l35.3 35.4c2.1 2.1 2.1 5.6 0 7.8l-5.2 5.2c-2.1 2.1-5.6 2.1-7.8 0L21.3 53.9c-2.2-2.2-2.2-5.6 0-7.8z" />
    </svg>
  ),
  'chevron-right': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M78.7 53.9L34.2 98.4c-2.1 2.1-5.6 2.1-7.8 0l-5.2-5.2c-2.1-2.1-2.1-5.6 0-7.8L56.5 50 21.3 14.6c-2.1-2.1-2.1-5.6 0-7.8l5.2-5.2c2.1-2.1 5.6-2.1 7.8 0l44.5 44.5c2.1 2.2 2.1 5.6-.1 7.8z" />
    </svg>
  ),
  'chevron-up': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M53.9 21.3l44.5 44.5c2.1 2.1 2.1 5.6 0 7.8l-5.2 5.2c-2.1 2.1-5.6 2.1-7.8 0L50 43.5 14.6 78.7c-2.1 2.1-5.6 2.1-7.8 0l-5.2-5.2c-2.1-2.1-2.1-5.6 0-7.8l44.5-44.5c2.2-2.1 5.6-2.1 7.8.1z" />
    </svg>
  ),
  'classification-document': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M53.9 21.3l44.5 44.5c2.1 2.1 2.1 5.6 0 7.8l-5.2 5.2c-2.1 2.1-5.6 2.1-7.8 0L50 43.5 14.6 78.7c-2.1 2.1-5.6 2.1-7.8 0l-5.2-5.2c-2.1-2.1-2.1-5.6 0-7.8l44.5-44.5c2.2-2.1 5.6-2.1 7.8.1z" />
    </svg>
  ),
  'classification-image': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M91,0H9C4,0,0,4,0,9v82c0,5,4,9,9,9h82c5,0,9-4,9-9V9C100,4,96,0,91,0z M90,90H10V10h80V90z M21.2,77.9L40,59l9.5,9.5l11.6-14.6l19.2,24.1H21.2z M29.9,37.6c-2.6,0-4.9-0.9-6.8-2.8c-1.9-1.8-2.8-4.1-2.8-6.8c0-2.6,0.9-4.9,2.8-6.8c1.9-1.9,4.1-2.8,6.8-2.8c2.7,0,5,0.9,6.8,2.8c1.8,1.9,2.8,4.1,2.8,6.8c0,2.7-0.9,5-2.8,6.8C34.9,36.7,32.6,37.6,29.9,37.6z" />
    </svg>
  ),
  'classification-page': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M80,32v58H20V10h38v22H80z M62.9,0H19c-5,0-9,4-9,9v82c0,5,4,9,9,9h62c5,0,9-4,9-9V27.1L62.9,0z" />
    </svg>
  ),
  'classify-and-unbundle': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M10,46V9c0-5,4-9,9-9h43.9L90,27.1V46H80V32H58V10H20v36H10z M80,72v18H20V72H10v19c0,5,4,9,9,9h62c5,0,9-4,9-9V72H80zM36,64h28V54H36V64z M28,54H0v10h28V54z M72,54v10h28V54H72z" />
    </svg>
  ),
  clipboard: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M10,46V9c0-5,4-9,9-9h43.9L90,27.1V46H80V32H58V10H20v36H10z M80,72v18H20V72H10v19c0,5,4,9,9,9h62c5,0,9-4,9-9V72H80zM36,64h28V54H36V64z M28,54H0v10h28V54z M72,54v10h28V54H72z" />
    </svg>
  ),
  'coffee-1': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M87.9 43.8h-1.2v-3.3-.2-.2c0-1.9-1.6-3.5-3.5-3.5h-79c-1.5 0-2.8.9-3.3 2.3-.2.5-.4 1-.4 1.6v4.8c0 1.1.1 2.1.1 3.1C1.7 63.7 11 76.8 24.1 83.3c.2 3.4 3 6.1 6.4 6.1h25.9c.3 0 .6 0 .9-.1 2.8-.5 5-2.9 5.2-5.8 7.5-3.6 13.8-9.3 18.1-16.4.3.1.7.2 1.1.2h6.2c6.5 0 11.8-5.3 11.8-11.8-.1-6.4-5.3-11.7-11.8-11.7zm-8.5 1.9c-.2 19.1-15.8 34.6-35 34.6h-1.6c-19.2 0-34.8-15.5-35-34.6v-2h71.7l-.1 2zm8.5 15.2h-4.2c1.3-3.3 2.2-6.9 2.7-10.6h1.5c2.9 0 5.3 2.4 5.3 5.3s-2.4 5.3-5.3 5.3zm-3.7 31.9H2.6c0 3.6 2.9 6.4 6.5 6.4h69.3c3.5 0 6.4-2.9 6.5-6.4h-.7zM45 .7c-1-1-2.6-1-3.5 0-4.6 4.6-4.6 12.1 0 16.7 2.6 2.7 2.6 6.9 0 9.6-1 1-1 2.6 0 3.5s2.6 1 3.5 0c4.6-4.6 4.6-12.1 0-16.7-2.6-2.7-2.6-6.9 0-9.6 1-.9 1-2.5 0-3.5zM29.8 8.3c-1-1-2.6-1-3.5 0-3.6 3.6-3.6 9.3 0 12.9 1.6 1.6 1.6 4.2 0 5.8-1 1-1 2.6 0 3.5 1 1 2.6 1 3.5 0 3.6-3.6 3.6-9.3 0-12.9-1.6-1.6-1.6-4.2 0-5.8 1-1 1-2.6 0-3.5zm30.4 0c-1-1-2.6-1-3.5 0-3.6 3.6-3.6 9.3 0 12.9 1.6 1.6 1.6 4.2 0 5.8-1 1-1 2.6 0 3.5 1 1 2.6 1 3.5 0 3.6-3.6 3.6-9.3 0-12.9-1.6-1.6-1.6-4.2 0-5.8.9-1 .9-2.6 0-3.5z" />
    </svg>
  ),
  'coffee-2': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M87.9 43.8h-1.2v-3.3-.2-.2c0-1.9-1.6-3.5-3.5-3.5h-79c-1.5 0-2.8.9-3.3 2.3-.2.5-.4 1-.4 1.6v4.8c0 1.1.1 2.1.1 3.1C1.7 63.7 11 76.8 24.1 83.3c.2 3.4 3 6.1 6.4 6.1h25.9c.3 0 .6 0 .9-.1 2.8-.5 5-2.9 5.2-5.8 7.5-3.6 13.8-9.3 18.1-16.4.3.1.7.2 1.1.2h6.2c6.5 0 11.8-5.3 11.8-11.8-.1-6.4-5.3-11.7-11.8-11.7zm-8.5 1.9c-.2 19.1-15.8 34.6-35 34.6h-1.6c-19.2 0-34.8-15.5-35-34.6v-2h71.7l-.1 2zm8.5 15.2h-4.2c1.3-3.3 2.2-6.9 2.7-10.6h1.5c2.9 0 5.3 2.4 5.3 5.3s-2.4 5.3-5.3 5.3zm-3.7 31.9H2.6c0 3.6 2.9 6.4 6.5 6.4h69.3c3.5 0 6.4-2.9 6.5-6.4h-.7zM41.5.7c-1 1-1 2.6 0 3.5 2.6 2.7 2.6 6.9 0 9.6-4.6 4.6-4.6 12.1 0 16.7.9 1 2.5.9 3.5 0s1-2.5 0-3.5c-2.6-2.7-2.6-6.9 0-9.6 4.6-4.6 4.6-12.1 0-16.7-.9-1-2.5-1-3.5 0zm15.2 11.1c1.6 1.6 1.6 4.2 0 5.8-3.6 3.6-3.6 9.3 0 12.9.9 1 2.5 1 3.5 0 1-.9 1-2.5 0-3.5-1.6-1.6-1.6-4.2 0-5.8 3.6-3.6 3.6-9.3 0-12.9-.9-1-2.5-1-3.5 0-1 .9-1 2.5 0 3.5zM26.3 8.3c-.9.9-.9 2.5 0 3.5 1.6 1.6 1.6 4.2 0 5.8-3.6 3.6-3.6 9.3 0 12.9.9 1 2.5 1 3.5 0 1-.9 1-2.5 0-3.5-1.6-1.6-1.6-4.2 0-5.8 3.6-3.6 3.6-9.3 0-12.9-.9-1-2.5-1-3.5 0z" />
    </svg>
  ),
  'coffee-fail': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M80.5 3l-.7.7-2.1-2-.1-.1-.1-.1c-1.2-1.2-3.2-1.2-4.3 0l-49 49.2c-.9.9-1.2 2.3-.6 3.5.2.4.4.9.7 1.2l3 3c.7.7 1.4 1.2 2 1.9 10.2 8.8 24.1 11.1 36.3 7 2.2 2 5.7 1.9 7.8-.2l.1-.1.1-.1 15.9-16c.2-.2.4-.4.5-.6 1.4-2.1 1.3-4.9-.4-6.8 2.4-6.9 2.8-14.4 1-21.4.2-.1.6-.3.8-.6l3.8-3.9c4-4 4-10.6 0-14.7C91-1 84.5-1 80.5 3zm-4.1 6.5c11.8 12 11.8 31.3-.1 43.2l-1 1c-11.9 12-31.2 12.1-43.2.4l-1.2-1.2L75.2 8.2l1.2 1.3zm14.7 4.1l-2.6 2.6c-1.3-2.9-2.9-5.6-4.9-8.2l.9-.9c1.8-1.8 4.8-1.8 6.6 0 1.8 1.7 1.8 4.7 0 6.5zM14.5 38.4c3.6 0 7.8 1.1 10.3 3.5.3.3 0 .8-.4.7-.9-.2-2-.3-2.8-.3-11.7 0-8.3 10.9-15.3 10.9-1.8 0-4.4-1-4.4-4.7 0-3.9 3.7-10.1 12.6-10.1zm11.2-1.9c.1.3-.3.5-.6.3-1.5-1.4-4.2-3.4-11.9-2.7-3 .3-6.4-2.1-4.7-5.1 2.1-3.7 9.3-2 11.6-.6 3.1 1.9 5.2 5.3 5.6 8.1zm47.5 54.7c-1.8.2-5.9 1.2-2.5 2.8 2.7 1.3-.7 6.1-29.1 6-23.6-.1-33.5-3.5-32.5-6.9.7-2.5 5.4-3.4 12.7-4.5 1.5-.2 3.2-1.4.2-2.6-1.4-.6-2.9-1.5-2.9-2.6 0-3.9 18.7-5.5 41.6-5.5 22.9 0 36.7 3.1 36.7 7 .1 3.2-6.6 4.4-24.2 6.3z" />
    </svg>
  ),
  coffeecup: (
    <svg viewBox="0 0 150 102" fill="currentColor">
      <path d="M132.6 18.8h-1.8v-5-.3-.3c0-2.9-2.4-5.3-5.3-5.3H6c-2.3 0-4.2 1.4-5 3.5-.3.8-.6 1.5-.6 2.4v7.3c0 1.7.2 3.2.2 4.7 1.7 23.1 15.7 42.9 35.5 52.8.3 5.1 4.5 9.2 9.7 9.2H85c.5 0 .9 0 1.4-.2 4.2-.8 7.6-4.4 7.9-8.8 11.3-5.4 20.9-14.1 27.4-24.8.5.2 1.1.3 1.7.3h9.4c9.8 0 17.8-8 17.8-17.8-.3-9.7-8.2-17.7-18-17.7zm-12.9 2.9C119.4 50.5 95.8 74 66.8 74h-2.4c-29 0-52.6-23.4-52.9-52.3v-3h108.4l-.2 3zm12.9 22.9h-6.4c2-5 3.3-10.4 4.1-16h2.3c4.4 0 8 3.6 8 8s-3.6 8-8 8zM127 92.9H3.6c0 5.4 4.4 9.7 9.8 9.7h104.8c5.3 0 9.7-4.4 9.8-9.7h-1z" />
    </svg>
  ),
  coffeesteam: (
    <svg viewBox="0 0 150 76" fill="currentColor">
      <path d="M66.1 1.6c-1.3 2.6-1.3 5.7 0 8.2l3.8 7.5c2.3 4.6 2.3 10.1 0 14.7l-3.8 7.5c-1.3 2.6-1.3 5.7 0 8.2l3.8 7.5c2.3 4.6 2.3 10.1 0 14.7l-3 5.9h-7.7c.2-.5.4-1.1.7-1.6l3.8-7.5c1.3-2.6 1.3-5.7 0-8.2L59.9 51c-2.3-4.6-2.3-10.1 0-14.7l3.8-7.5c1.3-2.6 1.3-5.7 0-8.2l-3.8-7.5C57.8 9 57.6 4.2 59.2 0h7.7l-.8 1.6zM83.9 0h-7.7c-1.6 4.2-1.4 9 .7 13.1l3.8 7.5c1.3 2.6 1.3 5.7 0 8.2l-3.8 7.5c-2.3 4.6-2.3 10.1 0 14.7l3.8 7.5c1.3 2.6 1.3 5.7 0 8.2l-3.8 7.5c-.3.5-.5 1.1-.7 1.6h7.7l3-5.9c2.3-4.6 2.3-10.1 0-14.7l-3.8-7.5c-1.3-2.6-1.3-5.7 0-8.2l3.8-7.5c2.3-4.6 2.3-10.1 0-14.7l-3.8-7.5c-1.3-2.6-1.3-5.7 0-8.2l.8-1.6zm-34 0h-7.7c-1.6 4.2-1.4 9 .7 13.1l3.8 7.5c1.3 2.6 1.3 5.7 0 8.2l-3.8 7.5c-2.3 4.6-2.3 10.1 0 14.7l3.8 7.5c1.3 2.6 1.3 5.7 0 8.2l-3.8 7.5c-.3.5-.5 1.1-.7 1.6h7.7l3-5.9c2.3-4.6 2.3-10.1 0-14.7l-3.8-7.5c-1.3-2.6-1.3-5.7 0-8.2l3.8-7.5c2.3-4.6 2.3-10.1 0-14.7l-3.8-7.5c-1.3-2.6-1.3-5.7 0-8.2l.8-1.6z" />
    </svg>
  ),
  'company-name': (
    <svg viewBox="0 0 50 50" fill="currentColor">
      <path d="m8.05 41.15v-26.9h10.7v-5.75l5.25-5 5.25 5v13.95h10.7v18.7zm2.25-2.25h5.95v-5.95h-5.95zm0-8.25h5.95v-5.95h-5.95zm0-8.2h5.95v-5.95h-5.95zm10.7 16.45h6v-5.95h-6zm0-8.25h6v-5.95h-6zm0-8.2h6v-5.95h-6zm0-8.2h6v-5.95h-6zm10.75 24.65h5.95v-5.95h-5.95zm0-8.25h5.95v-5.95h-5.95z" />
    </svg>
  ),
  'confidence-bar-1': (
    <svg viewBox="0 0 28 100" fill="currentColor">
      <path d="M0 67v33h16V56.3z" />
    </svg>
  ),
  'confidence-bar-2': (
    <svg viewBox="0 0 28 100" fill="currentColor">
      <path d="M0 48.2V100h16V37.5z" />
    </svg>
  ),
  'confidence-bar-3': (
    <svg viewBox="0 0 28 100" fill="currentColor">
      <path d="M0 29.5V100h16V18.8z" />
    </svg>
  ),
  'confidence-bar-4': (
    <svg viewBox="0 0 28 100" fill="currentColor">
      <path d="M16 0v100H0V10.7z" />
    </svg>
  ),
  'confidence-bars': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M100 0v100H84V10.7L100 0zM0 67v33h16V56.3L0 67zm28-18.8V100h16V37.5L28 48.2zm28-18.7V100h16V18.8L56 29.5z" />
    </svg>
  ),
  configure: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M96.9 75H31.2v-3.1c0-1.7-1.4-3.1-3.1-3.1h-6.2c-1.7 0-3.1 1.4-3.1 3.1V75H3.1C1.4 75 0 76.4 0 78.1v6.2c0 1.7 1.4 3.1 3.1 3.1h15.6v3.1c0 1.7 1.4 3.1 3.1 3.1H28c1.7 0 3.1-1.4 3.1-3.1v-3.1h65.6c1.7 0 3.1-1.4 3.1-3.1v-6.2c.2-1.7-1.2-3.1-2.9-3.1zm0-31.2H81.2v-3.1c0-1.7-1.4-3.1-3.1-3.1h-6.2c-1.7 0-3.1 1.4-3.1 3.1v3.1H3.1c-1.7 0-3.1 1.4-3.1 3.1v6.2c0 1.7 1.4 3.1 3.1 3.1h65.6v3.1c0 1.7 1.4 3.1 3.1 3.1H78c1.7 0 3.1-1.4 3.1-3.1v-3.1h15.6c1.7 0 3.1-1.4 3.1-3.1v-6.2c.2-1.7-1.2-3.1-2.9-3.1zm0-31.3H56.2V9.4c0-1.7-1.4-3.1-3.1-3.1h-6.2c-1.7 0-3.1 1.4-3.1 3.1v3.1H3.1c-1.7 0-3.1 1.4-3.1 3.1v6.2C0 23.6 1.4 25 3.1 25h40.6v3.1c0 1.7 1.4 3.1 3.1 3.1H53c1.7 0 3.1-1.4 3.1-3.1V25h40.6c1.7 0 3.1-1.4 3.1-3.1v-6.2c.2-1.8-1.2-3.2-2.9-3.2z" />
    </svg>
  ),
  crowdlabel: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M55 .2C78.6 2.6 97.4 21.4 99.8 45H89.7C87.4 26.9 73.1 12.6 55 10.3V.2zM10.3 45C12.6 26.9 26.9 12.6 45 10.3V.2C21.4 2.6 2.6 21.4.2 45h10.1zm79.4 10C87.4 73.1 73.1 87.4 55 89.7v10.1C78.6 97.4 97.4 78.6 99.8 55H89.7zM45 89.7C26.9 87.4 12.6 73.1 10.3 55H.2C2.6 78.6 21.4 97.4 45 99.8V89.7zM71 45H55V29c0-2.8-2.2-5-5-5s-5 2.2-5 5v16H29c-2.8 0-5 2.2-5 5s2.2 5 5 5h16v16c0 2.8 2.2 5 5 5s5-2.2 5-5V55h16c2.8 0 5-2.2 5-5s-2.3-5-5-5z" />
    </svg>
  ),
  currency: (
    <svg viewBox="0 0 50 50" fill="currentColor">
      <path d="m26.75 26.15q-2.1 0-3.55-1.45t-1.45-3.55 1.45-3.55 3.55-1.45 3.55 1.45 1.45 3.55-1.45 3.55-3.55 1.45zm-14.65 6.95q-1.15 0-2-0.875t-0.85-2.025v-18.1q0-1.15 0.85-2t2-0.85h29.3q1.2 0 2.05 0.85t0.85 2v18.1q0 1.15-0.85 2.025t-2.05 0.875zm3.75-2.3h21.85q0-1.8 1.275-3.075t3.075-1.275v-10.6q-1.8 0-3.075-1.275t-1.275-3.075h-21.85q0 1.8-1.275 3.075t-3.075 1.275v10.6q1.8 0 3.075 1.275t1.275 3.075zm22.5 7.8h-31.75q-1.2 0-2.05-0.85t-0.85-2v-20.55h2.25v20.55q0 0.2 0.2 0.4t0.45 0.2h31.75zm-26.25-7.8h-0.6t0-0.175 0-0.425v-18.1q0-0.25 0-0.425t0-0.175h0.6q-0.25 0-0.425 0.175t-0.175 0.425v18.1q0 0.25 0.175 0.425t0.425 0.175z" />
    </svg>
  ),
  dashboard: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M99.8 56.9c0 13.7-5.5 26.1-14.4 35.1 0 0-1 1-2.2 1-.7 0-1.5-.3-2.1-.9l-7.4-7.4c-1.2-1.1-1.2-3 0-4.2 1.1-1.2 3-1.2 4.2 0l2.4 2.4c5.4-6.3 8.9-14.3 9.5-23.1H86c-1.7 0-3-1.3-3-3s1.3-3 3-3h3.7c-.3-5-1.7-9.7-3.8-14 2.4-2.9 4.8-5.8 7-8.5 4.4 7.4 7 16.1 6.9 25.6zM22.1 80.7L19.8 83c-5.4-6.3-9-14.3-9.6-23h3.7c1.7 0 3-1.3 3-3s-1.3-3-3-3h-3.8c.6-8.8 4.1-16.8 9.5-23.1l2.4 2.4c.6.6 1.4.9 2.1.9.7 0 1.5-.3 2.1-.9 1.2-1.2 1.2-3.1 0-4.2l-2.4-2.4c6.3-5.4 14.3-9 23-9.6v3.7c0 1.7 1.3 3 3 3s3-1.3 3-3V17c4.8.3 9.4 1.6 13.6 3.4 2.8-2.4 5.6-4.8 8.3-7.1C67.4 9.2 59 6.8 50 6.8c-27.6 0-50 22.4-50 50 0 13.7 5.5 26.1 14.4 35.1.7.7 1.7 1.3 2.4 1.3s1.5-.3 2.1-.9l7.4-7.4c1.2-1.2 1.2-3.1 0-4.2-1.2-1.2-3.1-1.2-4.2 0zm63.7-60.4c-.9-.9-2.3-1.1-3.4-.4l-.1.1c-9.7 8.3-23.4 20-29.9 25.3-.7-.2-1.6-.3-2.4-.3-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12c0-1.1-.2-2.1-.4-3.1 5.3-6.7 16.5-20.4 24.6-30.2.7-1 .6-2.4-.4-3.4z" />
    </svg>
  ),
  'data-and-access': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M90.8 51H49V10.1c-1-.1-2-.1-3-.1C21.1 10 1 30.1 1 55s20.1 45 45 45 45-20.1 45-45c0-1.3-.1-2.7-.2-4zM46 90c-19.3 0-35-15.7-35-35 0-16.9 12-31 28-34.3V61h41.5C77.6 77.4 63.3 90 46 90zM57 .1V43h42.9C98.9 19.7 80.3 1.1 57 .1zM67 33V12.1c9.7 3.5 17.3 11.2 20.9 20.9H67z" />
    </svg>
  ),
  database: (
    <svg viewBox="0 0 448 512" fill="currentColor">
      <path d="M448 73.143v45.714C448 159.143 347.667 192 224 192S0 159.143 0 118.857V73.143C0 32.857 100.333 0 224 0s224 32.857 224 73.143zM448 176v102.857C448 319.143 347.667 352 224 352S0 319.143 0 278.857V176c48.125 33.143 136.208 48.572 224 48.572S399.874 209.143 448 176zm0 160v102.857C448 479.143 347.667 512 224 512S0 479.143 0 438.857V336c48.125 33.143 136.208 48.572 224 48.572S399.874 369.143 448 336z" />
    </svg>
  ),
  date: (
    <svg viewBox="0 0 50 50" fill="currentColor">
      <path d="m24 28.15q-0.75 0-1.25-0.525t-0.5-1.225q0-0.75 0.5-1.275t1.25-0.525 1.25 0.525 0.5 1.275q0 0.7-0.5 1.225t-1.25 0.525zm-8 0q-0.75 0-1.25-0.525t-0.5-1.225q0-0.75 0.5-1.275t1.25-0.525 1.25 0.525 0.5 1.275q0 0.7-0.5 1.225t-1.25 0.525zm16 0q-0.75 0-1.25-0.525t-0.5-1.225q0-0.75 0.5-1.275t1.25-0.525 1.25 0.525 0.5 1.275q0 0.7-0.5 1.225t-1.25 0.525zm-8 7.85q-0.75 0-1.25-0.525t-0.5-1.225q0-0.75 0.5-1.275t1.25-0.525 1.25 0.525 0.5 1.275q0 0.7-0.5 1.225t-1.25 0.525zm-8 0q-0.75 0-1.25-0.525t-0.5-1.225q0-0.75 0.5-1.275t1.25-0.525 1.25 0.525 0.5 1.275q0 0.7-0.5 1.225t-1.25 0.525zm16 0q-0.75 0-1.25-0.525t-0.5-1.225q0-0.75 0.5-1.275t1.25-0.525 1.25 0.525 0.5 1.275q0 0.7-0.5 1.225t-1.25 0.525zm-22.1 7q-1.2 0-2.05-0.85t-0.85-2.05v-28.95q0-1.15 0.85-2.025t2.05-0.875h3.7v-3.5h2.5v3.5h15.9v-3.5h2.45v3.5h3.65q1.2 0 2.05 0.875t0.85 2.025v28.95q0 1.2-0.85 2.05t-2.05 0.85zm0-2.25h28.2q0.25 0 0.45-0.2t0.2-0.45v-19.85h-29.5v19.85q0 0.25 0.2 0.45t0.45 0.2zm-0.65-22.75h29.5v-6.85q0-0.25-0.2-0.425t-0.45-0.175h-28.2q-0.25 0-0.45 0.175t-0.2 0.425zm0 0v-6.85q0-0.25 0-0.425t0-0.175 0 0.175 0 0.425v6.85z" />
    </svg>
  ),
  draggable: (
    <svg viewBox="0 0 322 514" fill="currentColor">
      <path d="m88 352c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40h-48c-22.09 0-40-17.9-40-40v-48c0-22.1 17.91-40 40-40h48zm192 0c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40h-48c-22.1 0-40-17.9-40-40v-48c0-22.1 17.9-40 40-40h48zm-240-32c-22.09 0-40-17.9-40-40v-48c0-22.1 17.91-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40h-48zm240-128c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40h-48c-22.1 0-40-17.9-40-40v-48c0-22.1 17.9-40 40-40h48zm-240-32c-22.09 0-40-17.9-40-40v-48c0-22.09 17.91-40 40-40h48c22.1 0 40 17.91 40 40v48c0 22.1-17.9 40-40 40h-48zm240-128c22.1 0 40 17.91 40 40v48c0 22.1-17.9 40-40 40h-48c-22.1 0-40-17.9-40-40v-48c0-22.09 17.9-40 40-40h48z" />
    </svg>
  ),
  droplet: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M53.5 1.9C52.7.7 51.4 0 50 0s-2.7.7-3.5 1.9c-3.1 4.8-30.1 47.6-30.1 64.6C16.5 85 31.5 100 50 100s33.5-15 33.5-33.5c0-17-27-59.8-30-64.6zm-3.4 83.9c-2.3 0-4.1-1.8-4.1-4.1s1.8-4.1 4.1-4.1c6.5 0 11.8-5.3 11.8-11.8 0-2.3 1.8-4.1 4.1-4.1s4.1 1.8 4.1 4.1c0 11-9 20-20 20z" />
    </svg>
  ),
  edit: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M68 12.2l8.8-8.8C79.9.3 84.8.3 88 3.3l8.7 8.7c3 3 3 8-.1 11.2L87.8 32 68 12.2zM32.5 87.3l49.7-49.7-19.8-19.8-49.7 49.7 19.8 19.8zM7.1 73.1c-.5.5-.9 1.2-1.1 2L1.1 95.7c-.4 2 1.3 3.6 3.2 3.2l20.8-5.1c.8-.2 1.4-.5 2-1.1l-20-19.6z" />
    </svg>
  ),
  education: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M50.9 57.2c-.6 0-1.2-.1-1.9-.3l-28.6-9.5c-.5 1.3-.8 3.1-.8 5.6 1.9 1.1 3.7 3.3 3.7 7 0 2.3-1.1 3.9-2.1 5 4.2 4.9 15.9 8.5 29.7 8.5 17.4 0 31.4-5.6 31.4-12.5V47.1L52.7 57c-.6.1-1.2.2-1.8.2zm47.8-24.8L51.5 16.7c-.4-.1-.8-.1-1.2 0L3.1 32.4c-.8.3-1.3 1-1.3 1.9 0 .8.5 1.6 1.3 1.9l10.8 3.6c-2.5 2.9-3.9 7-4.5 13.6-1.7.8-2.9 2.5-2.9 4.5 0 2.1 1.2 3.8 3 4.6C8 75.8 0 80.3 0 81.4c0 .6 6.2 3.9 7.7 3.9 2.3 0 7.7-3.9 7.7-12.2 0-4.9-.4-8.6-.9-11.3 1.3-.9 2.1-2.3 2.1-4 0-1.8-1-3.4-2.5-4.3.8-10.9 4.2-12.7 12.4-16.1.8-.3 2.4-.9 3.3-1.3 1.6-.7 3.1-1.2 4.4-1.8 4.5-1.8 8.3-3.2 11.2-4.3 3.2-1.1 4.6-1.5 4.6-1.6 1.2-.4 2.6.2 3 1.5.4 1.2-.2 2.6-1.5 3-.1 0-5.6 1.6-20 7.5-.9.4-1.8.7-2.6 1.1-1.7.7-3.1 1.2-4.3 1.8l25.6 8.5c.2.1.4.1.6.1.2 0 .4 0 .6-.1l47.1-15.7c.8-.3 1.3-1 1.3-1.9.2-.7-.3-1.5-1.1-1.8z" />
    </svg>
  ),
  ellipsis: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M64.5 50c0 8-6.5 14.5-14.5 14.5S35.5 58 35.5 50 42 35.5 50 35.5 64.5 42 64.5 50zm21-14.5C77.5 35.5 71 42 71 50s6.5 14.5 14.5 14.5S100 58 100 50s-6.5-14.5-14.5-14.5zm-71 0C6.5 35.5 0 42 0 50s6.5 14.5 14.5 14.5S29 58 29 50s-6.5-14.5-14.5-14.5z" />
    </svg>
  ),
  'elmos-fire': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M62.1 38.3l-7.7 16.1L46 46l16.1-7.7m12.8-12.8l-33.4 16-16 33.4 33.4-16 16-33.4zM50 10c22.1 0 40 17.9 40 40S72.1 90 50 90 10 72.1 10 50s17.9-40 40-40m0-10C22.4 0 0 22.4 0 50s22.4 50 50 50 50-22.4 50-50S77.6 0 50 0z" />
    </svg>
  ),
  email: (
    <svg viewBox="0 0 50 50" fill="currentColor">
      <path d="M0 25.6V21c0-3.9 3.2-7 7-7h86c3.8 0 7 3.1 7 7v4.6L52.8 46.2c-1.8.8-3.8.8-5.6 0L0 25.6zm52.8 31.7c-1.8.8-3.9.8-5.7 0L0 36.7V79c0 3.8 3.2 7 7 7h86c3.8 0 7-3.2 7-7V36.7L52.8 57.3z" />
    </svg>
  ),
  enterprise: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M59 25H40c-.8 0-2-1-2-2s1-2 2-2h19c1 0 2 1 2 2s-1 2-2 2zm0 10H40c-.8 0-2-1-2-2s1-2 2-2h19c1 0 2 1 2 2s-1 2-2 2zm0 10H40c-.8 0-2-1-2-2s1-2 2-2h19c1 0 2 1 2 2s-1 2-2 2zm0 10H40c-.8 0-2-1-2-2s1-2 2-2h19c1 0 2 1 2 2s-1 2-2 2zm0 10H40c-.8 0-2-1-2-2s1-2 2-2h19c1 0 2 1 2 2s-1 2-2 2zm0 10H40c-.8 0-2-1-2-2s1-2 2-2h19c1 0 2 1 2 2s-1 2-2 2zm41 22.5c0 1.5-1 2.5-3 2.5H3c-2 0-3-1-3-2.5S1 95 3 95h4V38c0-1 1-2 2-2h5v-6c0-1 1-2 2-2h9V10c0-1 1-2 2-2h8V2c0-1 1-2 2-2h26c1 0 2 1 2 2v6h8c1 0 2 1 2 2v18h9c1 0 2 1 2 2v6h5c1 0 2 1 2 2v57h4c2 0 3 1 3 2.5zM75 38h7v-6h-7v6zM39 10h22V4H39v6zM19 38h6v-6h-6v6zm6 57V41H13v54h12zm45 0V13H30v82h40zm18 0V41H75v54h13z" />
    </svg>
  ),
  'exclamation-circle-stroke': (
    <svg viewBox="0 0 100 100">
      <path
        fill="#FFFFFF"
        d="M50,0C22.4,0,0,22.4,0,50s22.4,50,50,50s50-22.4,50-50S77.6,0,50,0L50,0z"
      />
      <path
        fill="#F95359"
        d="M96,50c0,25.4-20.6,46-46,46S4,75.4,4,50
	C4,24.6,24.6,4,50,4S96,24.6,96,50z M50,59.3c-4.7,0-8.5,3.8-8.5,8.5s3.8,8.5,8.5,8.5s8.5-3.8,8.5-8.5S54.7,59.3,50,59.3z
	 M41.9,28.6l1.4,25.2c0.1,1.2,1,2.1,2.2,2.1h9c1.2,0,2.2-0.9,2.2-2.1l1.4-25.2c0.1-1.3-0.9-2.3-2.2-2.3H44.1
	C42.8,26.3,41.8,27.3,41.9,28.6L41.9,28.6z"
      />
    </svg>
  ),
  extraction: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M66.7 26.7c0-1.1-.4-2.2-1.2-2.9l-17-17c-.8-.8-1.8-1.2-3-1.2h-1.1v22.2h22.2v-1.1zM99.1 59L82.5 42.3c-1.8-1.8-4.8-.5-4.8 2v11.3h-11v11.1h11.1V78c0 2.5 3 3.7 4.8 2l16.6-16.8c1.1-1.2 1.1-3-.1-4.2zm-65.8 4.9v-5.6c0-1.5 1.3-2.8 2.8-2.8h30.6V33.3H43.1c-2.3 0-4.2-1.9-4.2-4.2V5.6H4.2C1.9 5.6 0 7.4 0 9.7v80.6c0 2.3 1.9 4.2 4.2 4.2h58.3c2.3 0 4.2-1.9 4.2-4.2V66.7H36.1c-1.5 0-2.8-1.3-2.8-2.8z" />
    </svg>
  ),
  eye: (
    <svg viewBox="0 0 18 12" fill="currentColor">
      <path d="M17.891 5.544C16.197 2.237 12.841 0 9 0 5.158 0 1.802 2.239.109 5.544a1.01 1.01 0 0 0 0 .912C1.803 9.763 5.159 12 9 12c3.842 0 7.197-2.239 8.891-5.544a1.01 1.01 0 0 0 0-.912zM9 10.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zM9 3a2.978 2.978 0 0 0-.791.118 1.495 1.495 0 0 1-2.09 2.091A2.993 2.993 0 1 0 9 3z" />
    </svg>
  ),
  file: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M56.2 26.6V0h-39c-2.6 0-4.7 2.1-4.7 4.7v90.6c0 2.6 2.1 4.7 4.7 4.7h65.6c2.6 0 4.7-2.1 4.7-4.7v-64H60.9c-2.5 0-4.7-2.2-4.7-4.7zm31.3-2.8V25h-25V0h1.2c1.2 0 2.4.5 3.3 1.4l19.1 19.1c.9.9 1.4 2.1 1.4 3.3z" />
    </svg>
  ),
  fileHappy: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M56.2,26.6V0h-39c-2.6,0-4.7,2.1-4.7,4.7v90.6c0,2.6,2.1,4.7,4.7,4.7h65.6c2.6,0,4.7-2.1,4.7-4.7v-64H60.9 C58.4,31.3,56.2,29.1,56.2,26.6z M31.3,51.5c-2,0-3.7-1.6-3.7-3.7c0-2,1.6-3.7,3.7-3.7c2,0,3.7,1.6,3.7,3.7S33.3,51.5,31.3,51.5z M68.7,44.2c2,0,3.7,1.6,3.7,3.7c0,2-1.6,3.7-3.7,3.7c-2,0-3.7-1.6-3.7-3.7S66.7,44.2,68.7,44.2z M61.4,63.6c0,6.2-5,11.2-11.2,11.2 S39,69.8,39,63.6l0,0H61.4z M87.5,23.8V25h-25V0h1.2c1.2,0,2.4,0.5,3.3,1.4l19.1,19.1C87,21.4,87.5,22.6,87.5,23.8z" />
    </svg>
  ),
  filter: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M95.3 0H4.7C.5 0-1.6 5.1 1.4 8l36.1 36.1v40.2c0 1.5.7 3 2 3.8L55.1 99c3.1 2.2 7.4 0 7.4-3.8V44.1L98.6 8c3-2.9.9-8-3.3-8z" />
    </svg>
  ),
  'filter-outline': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path
        d="M93.7,0H6.3C0.7,0-2.1,6.7,1.8,10.7l35.7,35.7v36.5v0c0,1.8,0.8,3.5,2.1,4.7
        l12.5,10.9c4,3.5,10.4,0.7,10.4-4.7V46.3l35.7-35.7C102.1,6.7,99.3,0,93.7,0L93.7,0z M56.3,43.8v50L43.8,82.8V43.8L6.2,6.2h87.5
        L56.3,43.8z"
      />
    </svg>
  ),
  folder: (
    <svg viewBox="0 0 514 514" fill="currentColor">
      <path d="M465 129H273l-64-64H49C22.49 65 1 86.49 1 113v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V177c0-26.51-21.49-48-48-48z" />
    </svg>
  ),
  form: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M81,0H19c-5,0-9,4-9,9v82c0,5,4,9,9,9h62c5,0,9-4,9-9V9C90,4,86,0,81,0z M80,90H20V10h60V90z M74,26H26v-6h48V26z M74,38H26v-6h48V38z M74,49.7H26v-6h48V49.7z M74,79.8H56v-6h18V79.8z M74,67.8H56v-6h18V67.8z M34.2,80l14.1-14.1L44,61.8l-9.9,10L30,67.7l-4,4.1L34.2,80z" />
    </svg>
  ),
  gallery: (
    <svg viewBox="0 0 102 102" fill="currentColor">
      <path d="M5.9 46H40c3.3 0 6-2.7 6-6V5.9c0-2.5-2.3-4.4-4.8-3.9C21.5 5.9 5.9 21.5 1.9 41.2c-.5 2.5 1.4 4.8 4 4.8zM36 13.9V36H13.9C18 26 26 18 36 13.9zM62 56c-3.3 0-6 2.7-6 6v34.2c0 2.5 2.3 4.4 4.8 3.9 19.8-4 35.4-19.6 39.3-39.3.5-2.5-1.4-4.8-3.9-4.8H62zm4 32.1V66h22.1C84 76 76 84 66 88.1zM40 56H5.9c-2.5 0-4.4 2.3-3.9 4.8 4 19.8 19.6 35.4 39.3 39.3 2.5.5 4.8-1.4 4.8-3.9V62c-.1-3.3-2.8-6-6.1-6zM13.9 66H36v22.1C26 84 18 76 13.9 66zM62 46h34.2c2.5 0 4.4-2.3 3.9-4.8-4-19.8-19.6-35.4-39.3-39.3-2.5-.5-4.8 1.4-4.8 4V40c0 3.3 2.7 6 6 6zm26.1-10H66V13.9C76 18 84 26 88.1 36z" />
    </svg>
  ),
  graphiql: (
    <svg viewBox="0 0 102 102" fill="currentColor">
      <path d="M90,62.8c-0.8-0.4-1.5-0.7-2.3-0.9V38.1c0.8-0.2,1.6-0.5,2.4-0.9c4.3-2.5,5.7-7.9,3.3-12.2c-2.5-4.3-7.9-5.7-12.2-3.3  c-0.8,0.4-1.4,1-2,1.6L58.5,11.5c0.2-0.8,0.4-1.7,0.4-2.5C58.9,4,54.9,0,50,0s-8.9,4-8.9,8.9c0,0.9,0.1,1.7,0.4,2.5L20.9,23.3  c-0.6-0.6-1.2-1.1-2-1.6c-4.3-2.5-9.7-1-12.2,3.3c-2.5,4.3-1,9.7,3.3,12.2c0.8,0.4,1.6,0.7,2.4,0.9v23.8c-0.8,0.2-1.6,0.5-2.3,0.9  C5.7,65.3,4.3,70.7,6.7,75s7.9,5.7,12.2,3.3c0.8-0.4,1.4-1,2-1.6l20.6,11.9c-0.2,0.8-0.4,1.6-0.4,2.5c0,4.9,4,8.9,8.9,8.9  s8.9-4,8.9-8.9c0-1-0.2-1.9-0.5-2.8l20.4-11.8c0.6,0.7,1.4,1.3,2.2,1.8c4.3,2.5,9.7,1,12.2-3.3C95.8,70.7,94.3,65.3,90,62.8z   M23.1,68.4c-0.2-0.8-0.5-1.6-0.9-2.3c-0.4-0.8-1-1.4-1.6-2l26.9-46.6c0.8,0.2,1.6,0.4,2.5,0.4c0.9,0,1.7-0.1,2.5-0.4l26.9,46.6  c-0.6,0.6-1.1,1.2-1.6,2c-0.4,0.8-0.7,1.5-0.9,2.3H23.1z M77,27c-0.6,2.2-0.4,4.7,0.8,6.9c1.3,2.2,3.3,3.6,5.6,4.2v23.8  c-0.1,0-0.2,0.1-0.3,0.1L56.2,15.4c0.1-0.1,0.2-0.2,0.2-0.2L77,27z M43.6,15.1c0.1,0.1,0.2,0.2,0.3,0.3L16.9,62  c-0.1,0-0.2-0.1-0.3-0.1V38.1c2.3-0.6,4.3-2,5.6-4.2c1.3-2.2,1.5-4.7,0.8-6.9L43.6,15.1z M56.6,85.1c-1.6-1.8-4-2.9-6.6-2.9  c-2.5,0-4.8,1-6.4,2.7L23,73c0-0.1,0.1-0.2,0.1-0.3h53.8c0,0.2,0.1,0.4,0.2,0.6L56.6,85.1z" />
    </svg>
  ),
  happy: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M100 50c0 27.6-22.4 50-50 50S0 77.6 0 50 22.4 0 50 0s50 22.4 50 50zm-78.6-7.4c-.1-3.1 2.5-5.7 5.7-5.7 3.1 0 5.7 2.6 5.7 5.7 0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5C37.7 36.7 32.8 32 27 32s-10.6 4.8-10.6 10.6c0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5zM73 32c-5.9 0-10.6 4.8-10.6 10.6 0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5c0-3.1 2.6-5.7 5.7-5.7s5.7 2.6 5.7 5.7c0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5C83.7 36.7 78.9 32 73 32zm4.8 24.6c-1.4-.6-3 0-3.5 1.4-4.2 9.7-13.7 16-24.3 16-10.6 0-20.1-6.3-24.3-16.1-.6-1.4-2.1-2-3.5-1.4-1.4.6-2 2.1-1.4 3.5 5.1 11.7 16.5 19.3 29.3 19.3 12.7 0 24.2-7.5 29.1-19.3.6-1.2-.1-2.8-1.4-3.4z" />
    </svg>
  ),
  'help-solid': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M50 0C22.4 0 0 22.4 0 50s22.4 50 50 50 50-22.4 50-50S77.6 0 50 0zm9.4 80.2c0 5.2-4.3 9.5-9.5 9.5s-9.5-4.3-9.5-9.5 4.3-9.5 9.5-9.5 9.5 4.2 9.5 9.5zM72.7 32c0 11.5-7.5 16.9-11.1 19.5l-.2.1c-3.5 2.5-4 4.6-4 6.4 0 4.2-3.5 5.9-7.8 5.9-4.2 0-7.5-1.8-7.5-6 0-7.6 3.6-14.1 10.4-18.7 3.7-2.6 4.8-4.2 4.8-7.2 0-.6-.2-5.3-7.6-5.3-3.7 0-6.3 1.5-7.4 5.1-1.3 4-4.4 6.2-8.2 6.2-5.7 0-7.8-4.4-6.7-9.1 2.3-10.2 11.4-17.3 22.5-17.3 16.7-.3 22.8 12 22.8 20.4z" />
    </svg>
  ),
  help: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M50 10c22.1 0 40 17.9 40 40S72.1 90 50 90 10 72.1 10 50s17.9-40 40-40m0-10C22.4 0 0 22.4 0 50s22.4 50 50 50 50-22.4 50-50S77.6 0 50 0zm0 18.4c-9.5 0-17.4 6-19.2 14.6-.3 1.3.3 4.9 4.9 4.9 3 0 4.4-1.2 4.9-2.9 1.1-3.8 4.7-6.7 9.3-6.7 9.1 0 9.4 6.6 9.4 7.2 0 4-2 6.1-5.5 8.6C48 48.3 45 53.7 45 60.3c0 2.8 2.3 4.1 5 4.1 2.9 0 5.1-1.2 5.1-4 0-2 .5-4.8 4.7-7.8 3.1-2.3 9.8-6.8 9.8-16.8-.1-7.1-5.3-17.4-19.6-17.4zm0 51c-3.5 0-6.4 2.8-6.4 6.4s2.8 6.4 6.4 6.4c3.5 0 6.4-2.8 6.4-6.4s-2.9-6.4-6.4-6.4z" />
    </svg>
  ),
  highlight: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M0 91.2l18.4 5.9 6.5-6.5-12.3-12.4L0 91.2zm22.9-44.1c-2 1.7-2.8 4.5-2 7l2.4 7.9-9.3 9.3L31.6 89l9.3-9.3 7.9 2.4c2.5.8 5.3 0 7-2l6.5-7.7-31.8-31.9-7.6 6.6zM97 17.5L85.4 5.9c-3.8-3.8-9.8-4-13.8-.4L35 36.7l31.2 31.2 31.2-36.6c3.6-4 3.4-10-.4-13.8z" />
    </svg>
  ),
  'highlight-outline': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path
        d="M97.2,16.9L86.1,5.8c-1.9-1.9-4.4-2.8-6.8-2.8c-2.4,0-4.6,0.9-6.4,2.4
          L20.3,51.1c-1.9,1.7-2.6,4.3-1.9,6.7l2.3,7.5L14,72c-1.2,1.2-1.2,3.2,0,4.5l0,0L0,91.2l18.4,5.9l8.1-8.1l0,0c1.2,1.2,3.3,1.2,4.5,0
          l6.7-6.7l7.5,2.3c3.3,1,5.8-0.8,6.7-1.9l45.7-52.6C101,26.3,100.8,20.5,97.2,16.9z M16.7,90.4l-5.9-1.9l7.4-7.7l4.1,4.1L16.7,90.4z
          M47.5,78.8C47.3,78.9,47.2,79,47,79c0,0-0.1,0-0.2,0l-7.5-2.3l-3.4-1l-7.3,7.3l-8.6-8.6l7.3-7.3l-1-3.4l-2.3-7.5
          c-0.1-0.2,0-0.4,0.1-0.6l8.3-7.2l22.2,22.2L47.5,78.8z M93.1,26.3L58.5,66.1L36.9,44.4L76.7,9.8C77.6,9,80,8,81.9,9.9L93,21.1
          C94.4,22.5,94.5,24.7,93.1,26.3L93.1,26.3z"
      />
    </svg>
  ),
  'indico-logo-white': (
    <svg viewBox="0 0 100 30" fill="currentColor">
      <path d="M59.6 20.1c0-5.5 4.5-9.9 9.9-9.9h7.2v3.5h-7.2c-3.5 0-6.2 2.9-6.2 6.2 0 3.5 2.9 6.2 6.2 6.2h7.2V30h-7.2c-5.4 0-9.9-4.5-9.9-9.9zM0 30h3.6V10.3H0V30zM1.8 4.3C.8 4.3 0 5 0 6s.7 1.7 1.8 1.7S3.5 7 3.5 6c.1-1-.7-1.7-1.7-1.7zm5 16V30h3.6v-9.7c0-3.5 2.9-6.2 6.2-6.2 3.5 0 6.2 2.9 6.2 6.2V30h3.6v-9.7c0-5.5-4.5-9.9-9.9-9.9-5.2-.1-9.7 4.4-9.7 9.9zM52.9 30h3.6V10.3h-3.6V30zm1.9-25.7c-1 0-1.8.7-1.8 1.7s.7 1.7 1.8 1.7c1 0 1.8-.8 1.8-1.7s-.8-1.7-1.8-1.7zM100 20c0 5.5-4.5 10-10 10s-10-4.5-10-10 4.5-10 10-10 10 4.5 10 10zm-16.1 0c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1c0-3.4-2.8-6.1-6.1-6.1s-6.1 2.7-6.1 6.1zm7.9 0c0 1-.8 1.7-1.7 1.7s-1.8-.7-1.8-1.7.8-1.7 1.8-1.7 1.7.7 1.7 1.7zM49.8.4V20.1c0 5.5-4.5 9.9-10 9.9s-10-4.5-10-10 4.5-10 10-10c2.4 0 4.6.9 6.4 2.4V.5h3.6V.4zM33.6 20c0 3.4 2.7 6.1 6.1 6.1 3.4 0 6.1-2.8 6.1-6.1 0-3.4-2.7-6.1-6.1-6.1-3.3 0-6.1 2.7-6.1 6.1z" />
    </svg>
  ),
  'indico-o': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M100 50c0 27.6-22.4 50-50 50S0 77.6 0 50 22.4 0 50 0s50 22.4 50 50zM50 19.4c-16.9 0-30.6 13.7-30.6 30.6S33.1 80.6 50 80.6 80.6 66.9 80.6 50 66.9 19.4 50 19.4zm0 21.7c-4.9 0-8.9 4-8.9 8.9s4 8.9 8.9 8.9 8.9-4 8.9-8.9-4-8.9-8.9-8.9z" />
    </svg>
  ),
  info: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path
        d="M50,6.5c23.9,0,43.5,19.4,43.5,43.5c0,24.1-19.5,43.5-43.5,43.5
	C26,93.5,6.5,74.1,6.5,50C6.5,26,25.9,6.5,50,6.5 M50,0C22.4,0,0,22.4,0,50c0,27.6,22.4,50,50,50s50-22.4,50-50
	C100,22.4,77.6,0,50,0z M42.7,69.4h2.4V45.2h-2.4c-1.3,0-2.4-1.1-2.4-2.4v-1.6c0-1.3,1.1-2.4,2.4-2.4h9.7c1.3,0,2.4,1.1,2.4,2.4
	v28.2h2.4c1.3,0,2.4,1.1,2.4,2.4v1.6c0,1.3-1.1,2.4-2.4,2.4H42.7c-1.3,0-2.4-1.1-2.4-2.4v-1.6C40.3,70.4,41.4,69.4,42.7,69.4z
	 M50,21c-3.6,0-6.5,2.9-6.5,6.5c0,3.6,2.9,6.5,6.5,6.5s6.5-2.9,6.5-6.5C56.5,23.9,53.6,21,50,21z"
      />
    </svg>
  ),
  kabob: (
    <svg viewBox="0 0 102 102" fill="currentColor">
      <path d="m50 18c-5 0-9-4-9-9s4-9 9-9 9 4 9 9-4 9-9 9zm9 73c0-5-4-9-9-9s-9 4-9 9 4 9 9 9 9-4 9-9zm0-41c0-5-4-9-9-9s-9 4-9 9 4 9 9 9 9-4 9-9z" />
    </svg>
  ),
  key: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M81.3 30c-3.1 3.1-8.1 3.1-11.4 0-3.1-3.1-3.1-8.1 0-11.4 3.1-3.1 8.1-3.1 11.4 0 3.1 3.3 3.1 8.3 0 11.4zm11.4-19.1C81.5-1.8 61.9-2.3 50.1 9.6c-7.9 7.9-10.3 19.1-7.4 29L2.5 78.9C1.4 80 .8 81.4.7 82.9L0 95.7c-.1 1.9 1.5 3.4 3.4 3.3l15.5-1.3c1.3-.2 2.4-.7 3.6-1.7V82.2h13v-13H51c6.5-6.2 11.3-11 11.3-11 10.5 3.1 22.4.2 30.2-8.6 9.9-10.8 10-27.7.2-38.7z" />
    </svg>
  ),
  label: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M98.6 45.4L78.9 17.7c-3-4.2-7.9-6.7-13.1-6.7H10.6c-8 .5-12.8 9-9.5 16.2L9.3 50 1 72.6c-3 6.6.8 14.6 8 16.1.8.2 1.6.3 2.5.3h54.4c5.2 0 10.1-2.5 13.1-6.7l19.5-27.7c2-2.9 2-6.4.1-9.2z" />
    </svg>
  ),
  'layout-complex': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M74 72H43v-4h31v4zM26 60v4h13v-4H26zm13 12v-4H26v4h13zm35-16v-4H43v4h31zm-35 0v-4H26v4h13zm4 4v4h31v-4H43zm47 35V5c0-2.7-3.2-5-6-5H15c-2.7 0-5 2.2-5 5v90c0 2.7 2.2 5 5 5h70c2.8 0 5-2.2 5-5zM79 76H21V48h58v28zm0-48H62v-4h17v4zm-23 8h-4v-4h4v4zm0-8h-4v-4h4v4zm23 8H62v-4h17v4zm-40-8H21v-4h18v4zm0 8H21v-4h18v4z" />
    </svg>
  ),
  'layout-simple': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M85 100H15c-2.7 0-5-2.2-5-5V5c0-2.7 2.2-5 5-5h69c2.7 0 6 2.2 6 5v90c0 2.8-2.2 5-5 5zM46 52H21v4h25v-4zm0 20H21v4h25v-4zm0-10H21v4h25v-4zm0-30H21v4h25v-4zm0-10H21v4h25v-4zm0 20H21v4h25v-4zm8-6h25v-4H54v4zm0-10h25v-4H54v4zm0 20h25v-4H54v4zm0 20h25v-4H54v4zm0 10h25v-4H54v4zm0-20h25v-4H54v4z" />
    </svg>
  ),
  location: (
    <svg viewBox="0 0 50 50" fill="currentColor">
      <path d="m22.85 43v-8.75h-11l-5.15-5.1 5.15-5.15h11v-4.35h-14.5v-10.3h14.5v-4.35h2.3v4.35h11l5.15 5.15-5.15 5.15h-11v4.35h14.5v10.25h-14.5v8.75zm-12.2-25.65h24.55l2.9-2.85-2.9-2.85h-24.55zm2.15 14.65h24.55v-5.75h-24.55l-2.9 2.9zm-2.15-14.65v-5.7 5.7zm26.7 14.65v-5.75 5.75z" />
    </svg>
  ),
  lock: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M79 46.2V29C79 13 66 0 50 0S21 13 21 29v17.2c-5.7 1-10 5.9-10 11.8v30c0 6.6 5.4 12 12 12h54c6.6 0 12-5.4 12-12V58c0-5.9-4.3-10.9-10-11.8zM49.2 14c8.7-.4 15.8 6.5 15.8 15v17H35V29.4c0-8 6.2-15 14.2-15.4zm.8 71c-4.4 0-8-3.6-8-8v-8c0-4.4 3.6-8 8-8s8 3.6 8 8v8c0 4.4-3.6 8-8 8z" />
    </svg>
  ),
  menu: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M94 24H6c-3.2 0-6-2.8-6-6s2.8-6 6-6h88c3.2 0 6 2.8 6 6s-2.8 6-6 6zM94 56H6c-3.2 0-6-2.8-6-6s2.8-6 6-6h88c3.2 0 6 2.8 6 6s-2.8 6-6 6zM94 88H6c-3.2 0-6-2.8-6-6s2.8-6 6-6h88c3.2 0 6 2.8 6 6s-2.8 6-6 6z" />
    </svg>
  ),
  'model-import': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M96.4,19.1L88.6,4.7C87,1.8,83.9,0,80.7,0H19.3c-3.3,0-6.3,1.8-7.9,4.7L3.6,19.1c-1,1.9-1.6,4-1.6,6.1V91c0,5,4,9,9,9h78c5,0,9-4,9-9V25.2C98,23.1,97.5,21,96.4,19.1z M19.9,10h60.1l6,11H13.9L19.9,10z M12,90V31h76v59H12z M67.8,57.8L50,40L32.2,57.8l5.7,5.6l8.1-8.1V80h8V55.3l8.1,8.1L67.8,57.8" />
    </svg>
  ),
  'model-starter': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M96.4,19.1L88.6,4.7C87,1.8,83.9,0,80.7,0H19.3c-3.3,0-6.3,1.8-7.9,4.7L3.6,19.1c-1,1.9-1.6,4-1.6,6.1V91c0,5,4,9,9,9h78c5,0,9-4,9-9V25.2C98,23.1,97.5,21,96.4,19.1z M19.9,10h60.1l6,11H13.9L19.9,10z M12,90V31h76v59H12z M66,64H54v12h-8V64H34v-8h12V44h8v12h12V64z" />
    </svg>
  ),
  moon: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M98.7 52.5c-.8-.4-2.1-.3-2.9.8-6.2 7.9-16.2 12.1-26.2 12.1C50.4 65.4 35 50 35 30.8c0-10 4.2-19.2 12.1-26.2.8-.8 1.1-2.2.8-2.9C47.3.3 46.2 0 45 0 19.2 2.5 0 23.8 0 50c0 27.9 22.5 50 50 50 25.8 0 47.5-19.2 50-45 .1-1.2-.4-2.1-1.3-2.5z" />
    </svg>
  ),
  moonbow: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M42 65c2.2 0 4 1.8 4 4v5c0 2.2-1.8 4-4 4H8.6c-2.7-4-4.8-8.3-6.3-13H42zm25-12v-5c0-2.2-1.8-4-4-4H.4c-.3 2-.4 4-.4 6 0 2.4.2 4.7.5 7H63c2.2 0 4-1.8 4-4zM51 23H7.9C5.4 27 3.4 31.4 2 36h49c2.2 0 4-1.8 4-4v-5c0-2.2-1.8-4-4-4zm49 27c0-27.6-22.4-50-50-50-.8 0-1.7 0-2.5.1-.2 0-.5 0-.7.1-.6 0-1.2.1-1.8.1-.3 0-.5.1-.8.1-.6.1-1.1.1-1.6.2-.3 0-.5.1-.8.1-.5.1-1.1.2-1.6.3-.3.1-.6.1-.8.2-.5.1-1 .2-1.5.4-.3.1-.6.2-.9.2-.5.1-1 .3-1.4.4l-.9.3c-.4.1-.9.3-1.3.4-.3.1-.7.2-1 .4-.4.1-.8.3-1.1.4-.5.2-.9.3-1.3.5-.3.1-.6.3-.9.4-.4.2-.9.4-1.3.6l-.6.3c-.5.3-1 .5-1.5.8-.1 0-.2.1-.3.1-3.2 1.8-6.2 4-9 6.5h18.4c4.7-1.9 9.8-3 15.2-3 22.1 0 40 17.9 40 40S72.1 90 50 90c-5.4 0-10.6-1.1-15.2-3H16.4c2.7 2.5 5.7 4.7 9 6.5.1 0 .2.1.3.1.5.3 1 .6 1.5.8l.6.3c.4.2.9.4 1.3.6.3.1.6.3.9.4.4.2.8.3 1.1.5.4.2.8.3 1.1.4.3.1.7.3 1 .4.4.2.9.3 1.3.4l.9.3c.5.1 1 .3 1.4.4.3.1.6.2.9.2.5.1 1 .2 1.5.4.3.1.6.1.8.2.5.1 1.1.2 1.6.3.3 0 .5.1.8.1.5.1 1.1.1 1.6.2.3 0 .5.1.8.1.6.1 1.2.1 1.8.1.2 0 .5 0 .7.1.8 0 1.7.1 2.5.1C77.6 100 100 77.6 100 50z" />
    </svg>
  ),
  'no-format': (
    <svg viewBox="0 0 50 50" fill="currentColor">
      <path d="m24 43q-3.95 0-7.425-1.5t-6.05-4.075-4.05-6.05-1.475-7.475q0-3.95 1.475-7.4t4.05-6.025 6.05-4.025 7.425-1.45 7.425 1.45 6.05 4.025 4.05 6.025 1.475 7.4q0 4-1.475 7.475t-4.05 6.05-6.05 4.075-7.425 1.5zm7.35-26h7.95q-1.75-3.8-4.875-6.225t-7.275-3.225q1.45 1.9 2.475 4.25t1.725 5.2zm-12.35 0h10.1q-0.65-2.8-1.975-5.3t-3.125-4.45q-1.7 1.55-2.875 3.85t-2.125 5.9zm-11.05 11.7h8.25q-0.15-1.4-0.2-2.55t-0.05-2.25q0-1.2 0.05-2.275t0.25-2.325h-8.3q-0.35 1.15-0.525 2.225t-0.175 2.375q0 1.35 0.175 2.475t0.525 2.325zm12.9 11.7q-1.45-1.8-2.525-4.225t-1.675-5.225h-7.95q1.9 3.9 4.8 6.125t7.35 3.325zm-12.15-23.4h7.95q0.65-2.75 1.675-5.075t2.475-4.325q-4.15 0.8-7.225 3.175t-4.875 6.225zm15.3 23.85q1.85-2.05 3.05-4.4t2-5.5h-10.05q0.75 3.1 1.95 5.5t3.05 4.4zm-5.45-12.15h10.95q0.2-1.55 0.275-2.6t0.075-2.2q0-1.05-0.075-2.075t-0.275-2.525h-10.95q-0.2 1.5-0.275 2.525t-0.075 2.075q0 1.15 0.075 2.2t0.275 2.6zm8.65 11.7q3.95-0.9 7.125-3.35t4.975-6.1h-7.9q-0.75 2.8-1.8 5.2t-2.4 4.25zm4.55-11.7h8.3q0.35-1.2 0.525-2.325t0.175-2.475q0-1.3-0.175-2.375t-0.525-2.225h-8.25q0.15 1.65 0.225 2.675t0.075 1.925q0 1.15-0.075 2.225t-0.275 2.575z" />
    </svg>
  ),
  'object-detection': (
    <svg viewBox="0 0 100 100">
      <g>
        <path d="M0,91V66h10v24h24v10H9C4,100,0,96,0,91z" />
        <path d="M90,90H66v10h25c5,0,9-4,9-9V66H90V90z" />
      </g>
      <g>
        <path d="M91,0H66v10h24v24h10V9C100,4,96,0,91,0z" />
        <path d="M10,10h24V0H9C4,0,0,4,0,9v25h10V10z" />
      </g>
      <rect x="32" y="32" width="36" height="36" />
    </svg>
  ),
  organizations: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M86.9 53.9C85.9 43 80.3 33.4 72 27.2V26c0-12.2-9.8-22-22-22s-22 9.8-22 22v1.2c-8.3 6.2-14 15.7-14.9 26.6C5.4 57.3 0 65 0 74c0 12.2 9.8 22 22 22 5.2 0 10-1.8 13.8-4.9C40.2 93 45 94 50 94s9.8-1 14.2-2.9C68 94.2 72.8 96 78 96c12.2 0 22-9.8 22-22 0-9-5.4-16.7-13.1-20.1zM50 14c6.6 0 12 5.4 12 12s-5.4 12-12 12-12-5.4-12-12 5.4-12 12-12zM22 86c-6.6 0-12-5.4-12-12s5.4-12 12-12 12 5.4 12 12-5.4 12-12 12zm20.2-3.2c1.2-2.7 1.8-5.7 1.8-8.8 0-11.7-9.1-21.2-20.5-21.9 1-5.6 3.9-10.7 7.8-14.5C35.2 43.8 42.1 48 50 48s14.8-4.2 18.7-10.4c4 3.8 6.8 8.8 7.8 14.5C65.1 52.8 56 62.3 56 74c0 3.1.7 6.1 1.8 8.8-2.4.8-5.1 1.2-7.8 1.2s-5.4-.4-7.8-1.2zM78 86c-6.6 0-12-5.4-12-12s5.4-12 12-12 12 5.4 12 12-5.4 12-12 12z" />
    </svg>
  ),
  output: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M56.2 26.6V0h-39c-2.6 0-4.7 2.1-4.7 4.7v90.6c0 2.6 2.1 4.7 4.7 4.7h65.6c2.6 0 4.7-2.1 4.7-4.7V31.2H60.9c-2.5 0-4.7-2.1-4.7-4.6zm15 41.2L52.3 86.5a3.32 3.32 0 01-4.7 0L28.8 67.8c-2-2-.6-5.3 2.2-5.3h12.7V46.9c0-1.7 1.4-3.1 3.1-3.1H53c1.7 0 3.1 1.4 3.1 3.1v15.6H69c2.8 0 4.2 3.4 2.2 5.3zm14.9-47.3L67 1.4C66.1.5 64.9 0 63.7 0h-1.2v25h25v-1.2c0-1.2-.5-2.4-1.4-3.3z" />
    </svg>
  ),
  'page-thumbnail': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M56.2 26.6V0h-39c-2.6 0-4.7 2.1-4.7 4.7v90.6c0 2.6 2.1 4.7 4.7 4.7h65.6c2.6 0 4.7-2.1 4.7-4.7v-64H60.9c-2.5 0-4.7-2.2-4.7-4.7zm12.6 46.1c0 1.3-1.1 2.3-2.3 2.3H33.6c-1.3 0-2.3-1.1-2.3-2.3v-1.6c0-1.3 1.1-2.3 2.3-2.3h32.8c1.3 0 2.3 1.1 2.3 2.3v1.6zm0-12.5c0 1.3-1.1 2.3-2.3 2.3H33.6c-1.3 0-2.3-1.1-2.3-2.3v-1.6c0-1.3 1.1-2.3 2.3-2.3h32.8c1.3 0 2.3 1.1 2.3 2.3v1.6zm0-14.1v1.6c0 1.3-1.1 2.3-2.3 2.3H33.6c-1.3 0-2.3-1.1-2.3-2.3v-1.6c0-1.3 1.1-2.3 2.3-2.3h32.8c1.3 0 2.4 1 2.4 2.3zm18.7-22.3V25h-25V0h1.2c1.2 0 2.4.5 3.3 1.4l19.1 19.1c.9.9 1.4 2.1 1.4 3.3z" />
    </svg>
  ),
  'pay-as-you-go': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M69.1 66.8c1 1 1.5 2.7.1 4.1-1.6 1.6-4.1 0-4.9-.8 0 0-18.1-15.7-18.3-15.9-1.2-1.2-1.8-2.6-1.6-4.2L46 19.4c.2-2 .8-3.4 2.8-3.4 2.2 0 2.8 1.2 2.8 3.4L54.2 47c0 .6.4 1.4.8 1.8l14.1 18zm16.4 18.6c1.4-1.4 1.4-3.6 0-4.9-1.4-1.4-3.6-1.4-4.9 0-8.2 8-19 12.5-30.5 12.5C26.3 93 7 73.7 7 50S26.3 7 50.1 7c1.9 0 3.5-1.6 3.5-3.5S52 0 50.1 0C22.5 0 0 22.4 0 50s22.5 50 50.1 50c13.4 0 25.9-5.2 35.4-14.6zm3.5-9.6c-1.7-1-2.3-3.1-1.3-4.7 1-1.7 3.1-2.3 4.8-1.3 1.7 1 2.3 3.1 1.3 4.8-.7 1.2-1.9 1.8-3.1 1.8-.6-.1-1.2-.3-1.7-.6zm5.9-12.6c-1.9-.4-3.1-2.3-2.7-4.1.4-1.9 2.3-3.1 4.1-2.7 1.9.4 3.1 2.3 2.7 4.1-.4 1.7-1.9 2.8-3.5 2.8-.1 0-.4-.1-.6-.1zm-1.9-17c-.2-1.9 1.3-3.6 3.2-3.8 1.9-.2 3.6 1.2 3.8 3.2.2 1.9-1.2 3.6-3.2 3.8h-.4c-1.7 0-3.2-1.4-3.4-3.2zm-3-12.5c-.7-1.8.1-3.8 1.9-4.6 1.8-.7 3.8.1 4.6 1.9.7 1.8-.1 3.8-1.9 4.5-.5.2-1 .3-1.4.3-1.4.1-2.7-.8-3.2-2.1zm-6.6-11c-1.2-1.5-1-3.7.5-4.9 1.5-1.2 3.7-1 4.9.5 1.2 1.5 1 3.7-.5 4.9-.7.5-1.5.8-2.3.8-1-.1-1.9-.5-2.6-1.3zm-9.6-8.6c-1.6-1.1-2-3.2-1-4.8 1.1-1.6 3.2-2.1 4.8-1 1.6 1.1 2 3.2 1 4.8-.7 1.1-1.8 1.6-3 1.6-.6-.1-1.2-.3-1.8-.6zM62.1 8.7c-1.9-.5-2.9-2.5-2.4-4.3.5-1.8 2.5-2.9 4.3-2.4 1.9.5 2.9 2.5 2.4 4.3-.4 1.6-1.8 2.6-3.4 2.5-.3 0-.6 0-.9-.1z" />
    </svg>
  ),
  person: (
    <svg viewBox="0 0 50 50" fill="currentColor">
      <path d="m24 23.35q-2.9 0-4.775-1.875t-1.875-4.775 1.875-4.75 4.775-1.85 4.775 1.85 1.875 4.75-1.875 4.775-4.775 1.875zm-15 15.25v-3.8q0-1.6 0.85-2.8t2.2-1.85q3.2-1.4 6.125-2.1t5.825-0.7 5.825 0.725 6.075 2.125q1.4 0.65 2.25 1.825t0.85 2.775v3.8zm2.25-2.25h25.5v-1.55q0-0.75-0.5-1.475t-1.3-1.125q-2.95-1.45-5.575-2.025t-5.375-0.575-5.425 0.575-5.575 2.025q-0.75 0.4-1.25 1.125t-0.5 1.475zm12.75-15.25q1.85 0 3.1-1.25t1.25-3.15q0-1.85-1.25-3.1t-3.1-1.25-3.1 1.25-1.25 3.1q0 1.9 1.25 3.15t3.1 1.25zm0-4.4zm0 19.65z" />
    </svg>
  ),
  phone: (
    <svg viewBox="0 0 50 50" fill="currentColor">
      <path d="m20.15 40.9h7.7v-1.75h-7.7zm-6.9-5.8h21.5v-25.45h-21.5zm0.65 9.9q-1.2 0-2.05-0.85t-0.85-2.05v-36.2q0-1.2 0.85-2.05t2.05-0.85h20.2q1.2 0 2.05 0.85t0.85 2.05v36.2q0 1.2-0.85 2.05t-2.05 0.85zm-0.65-37.6h21.5v-1.5q0-0.25-0.2-0.45t-0.45-0.2h-20.2q-0.25 0-0.45 0.2t-0.2 0.45zm0-2.15t0 0.175 0 0.475v1.5-1.5q0-0.3 0-0.475t0-0.175zm0 32.1v4.75q0 0.25 0.2 0.45t0.45 0.2h20.2q0.25 0 0.45-0.2t0.2-0.45v-4.75zm0 5.4t0-0.175 0-0.475v-4.75 4.75q0 0.3 0 0.475t0 0.175z" />
    </svg>
  ),
  pin: (
    <svg viewBox="0 0 102 102" fill="currentColor">
      <path d="M63.9,72.1c-0.7-2.9,0.1-6,2.1-8.3l20.9-23.2c0,0,9.3,2.4,11.9-0.1c4.2-4.2-2.9-14.4-13.9-25.4 C75.8,6,63.5-3,59.4,1.1C56.9,3.7,59.3,13,59.3,13L36.1,33.9c-2.2,2-5.3,2.8-8.3,2.1c-6-1.4-16.1-3.1-19.8,0.6 c-2.2,2.2-2.4,5.7,0.8,8.8l18.5,18.5L1.3,94.6c-3.3,3.7,0.3,7.3,4,4l30.7-25.9l18.5,18.5c3.3,3.3,6.6,3,8.8,0.8 C67,88.2,65.3,78.1,63.9,72.1z" />
    </svg>
  ),
  plus: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M94 44H56V6c0-3.3-2.7-6-6-6s-6 2.7-6 6v38H6c-3.3 0-6 2.7-6 6s2.7 6 6 6h38v38c0 3.3 2.7 6 6 6s6-2.7 6-6V56h38c3.3 0 6-2.7 6-6s-2.7-6-6-6z" />
    </svg>
  ),
  pointer: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M80.8 64.3H60.1L71 90.8c.8 1.8-.1 3.9-1.8 4.7l-9.6 4.2c-1.8.8-3.8-.1-4.6-1.9L44.6 72.6 27.7 90c-2.3 2.3-5.9.5-5.9-2.5V3.6c0-3.2 3.9-4.8 5.9-2.5l55.5 57.1c2.3 2.2.6 6.1-2.4 6.1z" />
    </svg>
  ),
  popup: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M78 65c0-3.9 3.1-7 7-7s7 3.1 7 7v25.7c0 5.1-4.2 9.3-9.3 9.3H9.3C4.2 100 0 95.8 0 90.7V17.3C0 12.2 4.2 8 9.3 8H35c3.9 0 7 3.1 7 7s-3.1 7-7 7H14v64h64V65zM93 0H57c-3.9 0-7 3.1-7 7s3.1 7 7 7h17L41.5 46.5c-3 3-3 8 0 11s8 3 11 0L86 24v19c0 3.9 3.1 7 7 7s7-3.1 7-7V7c0-3.9-3.1-7-7-7z" />
    </svg>
  ),
  price: (
    <svg viewBox="0 0 50 50" fill="currentColor">
      <path d="m25.8 43q-0.45 0-0.875-0.15t-0.825-0.5l-18.4-18.45q-0.4-0.35-0.55-0.775t-0.15-0.925v-14.95q0-0.95 0.65-1.6t1.6-0.65h14.95q0.45 0 0.925 0.15t0.825 0.5l18.35 18.35q0.8 0.75 0.8 1.7t-0.75 1.7l-14.95 14.95q-0.3 0.3-0.725 0.475t-0.875 0.175zm0.05-2.2 14.95-14.95-18.55-18.6h-15v14.95zm-13.4-26.15q0.9 0 1.55-0.675t0.65-1.575-0.65-1.55-1.55-0.65-1.55 0.65-0.65 1.55 0.65 1.575 1.55 0.675z" />
    </svg>
  ),
  'radio-button': (
    <svg viewBox="0 0 102 102" fill="currentColor">
      <g transform="translate(1 1)">
        <path d="m49.9 72.5c6.3 0 11.6-2.2 15.9-6.5s6.5-9.7 6.5-15.9-2.2-11.6-6.5-15.9-9.7-6.5-15.9-6.5-11.5 2.1-15.9 6.4c-4.3 4.3-6.5 9.7-6.5 15.9s2.2 11.6 6.5 16 9.7 6.5 15.9 6.5zm0 27.5c-6.8 0-13.3-1.3-19.4-3.9s-11.4-6.2-15.9-10.7-8.1-9.8-10.7-15.9-3.9-12.5-3.9-19.4 1.3-13.4 3.9-19.5 6.2-11.4 10.7-15.9 9.8-8.1 15.9-10.7 12.5-4 19.4-4 13.4 1.3 19.5 4 11.4 6.2 15.9 10.7 8 9.7 10.7 15.8 4 12.6 4 19.6c0 6.9-1.3 13.4-4 19.5s-6.2 11.4-10.7 15.9-9.8 8.1-15.8 10.7c-6.1 2.5-12.6 3.8-19.6 3.8zm0-9.5c11.3 0 20.8-3.9 28.6-11.8s11.8-17.4 11.8-28.6c0-11.3-3.9-20.8-11.8-28.6s-17.3-11.8-28.6-11.8c-11.2 0-20.7 3.9-28.6 11.8s-11.8 17.3-11.8 28.6c0 11.2 3.9 20.7 11.8 28.6s17.5 11.8 28.6 11.8z" />
      </g>
    </svg>
  ),
  recruiter: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M50 21c-16 0-29 13-29 29 0 6.2 1.9 11.9 5.3 16.6-.1.1-.3.2-.4.3l-24 24c-2 2-2 5.2 0 7.2s5.2 2 7.2 0L33 74.2c.1-.1.2-.3.3-.4C38.1 77.1 43.8 79 50 79c16 0 29-13 29-29S66 21 50 21zm0 48c-10.5 0-19-8.5-19-19s8.5-19 19-19 19 8.5 19 19-8.5 19-19 19zm50-19c0 27.6-22.4 50-50 50-9.2 0-17.8-2.5-25.2-6.8l7.4-7.4C37.5 88.5 43.6 90 50 90c22.1 0 40-17.9 40-40S72.1 10 50 10 10 27.9 10 50c0 6.4 1.5 12.5 4.2 17.8l-7.4 7.4C2.5 67.8 0 59.2 0 50 0 22.4 22.4 0 50 0s50 22.4 50 50z" />
    </svg>
  ),
  regex: (
    <svg viewBox="0 0 50 50" fill="currentColor">
      <path d="M28.85 39.1V36.85H34Q35.2 36.85 36.025 36.025Q36.85 35.2 36.85 34V29.1Q36.85 27.35 37.925 25.975Q39 24.6 40.65 24.15V23.85Q39 23.4 37.925 22.025Q36.85 20.65 36.85 18.9V14Q36.85 12.8 36.025 11.975Q35.2 11.15 34 11.15H28.85V8.9H34Q36.15 8.9 37.625 10.4Q39.1 11.9 39.1 14V18.9Q39.1 20.15 39.95 20.975Q40.8 21.8 42.05 21.8H42.9V26.2H42.05Q40.8 26.2 39.95 27.025Q39.1 27.85 39.1 29.1V34Q39.1 36.1 37.6 37.6Q36.1 39.1 34 39.1ZM14 39.1Q11.85 39.1 10.375 37.6Q8.9 36.1 8.9 34V29.1Q8.9 27.85 8.05 27.025Q7.2 26.2 5.95 26.2H5.1V21.8H5.95Q7.2 21.8 8.05 20.975Q8.9 20.15 8.9 18.9V14Q8.9 11.9 10.4 10.4Q11.9 8.9 14 8.9H19.15V11.15H14Q12.8 11.15 11.975 11.975Q11.15 12.8 11.15 14V18.9Q11.15 20.65 10.075 22.025Q9 23.4 7.35 23.85V24.15Q9 24.6 10.075 25.975Q11.15 27.35 11.15 29.1V34Q11.15 35.2 11.975 36.025Q12.8 36.85 14 36.85H19.15V39.1Z" />
    </svg>
  ),
  reject: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M50 0C22.4 0 0 22.4 0 50s22.4 50 50 50 50-22.4 50-50S77.6 0 50 0zm26.2 23.8C89.4 37 90.3 57.1 80.4 71.3L28.7 19.6c14.2-9.9 34.3-9 47.5 4.2zM23.8 76.2C10.6 63 9.7 42.9 19.6 28.7l51.7 51.7c-14.2 10-34.3 9-47.5-4.2z" />
    </svg>
  ),
  repair: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M98.2 36.7c1.2 4.3 1.8 8.8 1.8 13.5-.1 26.3-21.1 48.4-47.4 49.7-29.7 1.5-54-22.8-52.5-52.5C1.4 21.5 22.8.6 48.8 0c18.9-.5 35.6 9.6 44.4 24.8L47.6 70.4c-1.2 1.2-3.1 1.2-4.2 0L21.9 49c-1.2-1.2-1.2-3.1 0-4.2l4.2-4.2c1.2-1.2 3.1-1.2 4.2 0l15.1 15.1 33.3-33.3C71.2 14.3 60.3 9.5 48.3 10c-20.2.8-36.8 17-38.2 37.1C8.5 70.6 27 90 50 90c22.1 0 40-17.9 40-40 0-1.6-.1-3.1-.3-4.7l8.5-8.6z" />
    </svg>
  ),
  research: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M84.6 84.3L62.2 49.6c-.3-.7-.7-1-.7-1.7V37c2.1 0 3.5-1.4 3.5-3.5S63.6 30 61.5 30h-21c-1.7 0-3.5 1.4-3.5 3.5s1.4 3.5 3.5 3.5v10.8c0 .7 0 1-.3 1.4l-22.7 35c-3.8 7 1 15.7 9.1 15.7h49c7.6.1 13.2-8.3 9-15.6zM46.1 53.1c1-1.7 1.4-3.5 1.4-5.2V37h7v10.8c0 1.7.3 3.5 1.4 5.2l7.7 12H38.4l7.7-11.9zM75.5 93h-49c-2.8 0-4.2-3.1-3.1-4.9l10.5-15.7h34.3l10.1 15.7c1.4 2.1-.4 4.9-2.8 4.9zM51 25.8c-4 0-7.3-3.3-7.3-7.3s3.3-7.3 7.3-7.3 7.3 3.3 7.3 7.3-3.3 7.3-7.3 7.3zm0-9.6c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3zM69.9 18.3c-4.9 0-8.8-4-8.8-8.8s4-8.8 8.8-8.8c4.9 0 8.8 4 8.8 8.8s-3.9 8.8-8.8 8.8zm0-12.6c-2.1 0-3.8 1.7-3.8 3.8s1.7 3.8 3.8 3.8c2.1 0 3.8-1.7 3.8-3.8S72 5.7 69.9 5.7z" />
    </svg>
  ),
  retrain: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M98.4 20.3l-26 17.2c-1 .7-2.4-.1-2.4-1.3V26H21v25.1c0 .3-.2.7-.5.8-1.9 1.2-8.9 5.6-12 7.6-.7.4-1.5-.1-1.5-.8V16c0-2.2 1.8-4 4-4h59V1.8c0-1.2 1.4-2 2.4-1.3l26 17.2c1 .6 1 2 0 2.6zM79 48.9V74H30V63.8c0-1.2-1.4-2-2.4-1.3l-26 17.2c-1 .6-1 2 0 2.6l26 17.2c1 .7 2.4-.1 2.4-1.3V88h59c2.2 0 4-1.8 4-4V41.3c0-.8-.8-1.2-1.5-.8-3.1 2-10.2 6.4-12 7.6-.3.2-.5.5-.5.8z" />
    </svg>
  ),
  sad: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M100 50c0 27.6-22.4 50-50 50S0 77.6 0 50 22.4 0 50 0s50 22.4 50 50zM61.6 70.5c-3.2-2.4-7.1-3.9-11.6-3.9s-8.5 1.4-11.6 3.9c-1.9 1.5-3.2 2.9-3.9 4.1-.9 1.5-.5 3.5 1 4.4 1.5.9 3.5.5 4.4-1 .3-.5 1.1-1.4 2.3-2.3C44.3 74 46.9 73 50 73s5.7 1 7.8 2.5c1.2.9 2 1.9 2.3 2.3.9 1.5 2.9 2 4.4 1 1.5-.9 2-2.9 1-4.4-.7-1-1.9-2.5-3.9-3.9zM24.5 44.9c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm52 .2c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zM29.8 34c-.5-1.3-2-1.9-3.3-1.3l-12 5c-1.3.5-1.9 2-1.3 3.3.4 1 1.3 1.5 2.3 1.5.3 0 .6-.1 1-.2l12-5c1.2-.5 1.8-2 1.3-3.3zm55.7 3.7l-12-5c-1.3-.5-2.7.1-3.3 1.3-.5 1.3.1 2.7 1.3 3.3l12 5c.3.1.6.2 1 .2 1 0 1.9-.6 2.3-1.5.5-1.3-.1-2.8-1.3-3.3z" />
    </svg>
  ),
  search: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M39.4.4C17.9.4.4 17.9.4 39.4s17.5 39 39 39c8.8 0 16.9-2.9 23.4-7.8-.3 1.9.2 3.8 1.7 5.2l22 22c1.2 1.2 2.7 1.8 4.3 1.8s3.1-.6 4.3-1.8l2.8-2.8c2.4-2.4 2.4-6.2 0-8.5l-22-22c-1.2-1.2-2.7-1.8-4.2-1.8-.3 0-.7 0-1 .1 4.9-6.5 7.8-14.6 7.8-23.4C78.4 17.9 61 .4 39.4.4zm0 63c-13.2 0-24-10.8-24-24s10.8-24 24-24 24 10.8 24 24c0 13.3-10.7 24-24 24z" />
    </svg>
  ),
  shrug: (
    <svg viewBox="0 0 250 66" fill="currentColor">
      <path d="M2.1 4.7C.7 4.7 0 3.6 0 2.3 0 .9.7 0 2.1 0h15.8c1.4 0 2 .9 2 2.3s-.6 2.4-2 2.4H2.1zm47.6 57.7c.2.4.3.8.3 1.2 0 1-.7 1.8-1.9 2.2-.4.1-.9.2-1.2.2-1.1 0-1.9-.5-2.4-1.6L21.7 3.7c-.1-.4-.2-.8-.2-1.1 0-1 .7-1.8 1.9-2.3.4-.1.8-.2 1.1-.2 1.1 0 2 .5 2.4 1.7l22.8 60.6zm4.1 3.6c-1.4 0-2.1-1-2.1-2.4s.7-2.3 2.1-2.3h23.8c1.4 0 2 .9 2 2.3s-.6 2.4-2 2.4H53.8zM232.1 4.7c-1.4 0-2-1-2-2.4s.6-2.3 2-2.3h15.8c1.4 0 2.1.9 2.1 2.3s-.7 2.4-2.1 2.4h-15.8zm-9-2.8c.4-1.2 1.2-1.7 2.4-1.7.3 0 .7.1 1.1.2 1.2.4 1.9 1.2 1.9 2.3 0 .3-.1.7-.2 1.1l-22.7 60.6c-.5 1.1-1.3 1.6-2.4 1.6-.3 0-.8-.1-1.2-.2-1.1-.4-1.9-1.2-1.9-2.2 0-.3.1-.8.3-1.2l22.7-60.5zM171.4 66c-1.4 0-2-1-2-2.4s.6-2.3 2-2.3h24.8c1.4 0 2.1.9 2.1 2.3s-.7 2.4-2.1 2.4h-24.8zM90.8 3.5c0 .5-.2 1-.6 1.6-6.1 8.2-9.7 16.3-9.7 28.1 0 11.9 3.6 20.1 9.6 28.2.4.6.6 1.2.6 1.6 0 1-.6 1.7-1.4 2.3-.6.5-1.3.7-2 .7-.8 0-1.6-.4-2.3-1.3-6.9-9.5-10.5-18.6-10.5-31.5 0-12.9 3.6-21.9 10.6-31.3.7-.9 1.6-1.3 2.4-1.3.7 0 1.3.2 1.8.6.8.5 1.5 1.3 1.5 2.3zm10.6 1.4c1 0 1.8.5 2.4 1.4 2.8 5.2 5.5 12.5 7.2 18.1.1.5.2.9.2 1.2 0 1.2-.7 2.1-2 2.5-.4.1-.8.2-1.2.2-1.2 0-2.2-.8-2.6-2-1.5-5.3-4.5-12.7-6.8-17.5-.2-.5-.3-.9-.3-1.3 0-1.1.6-2 1.8-2.4.4-.1.8-.2 1.3-.2zm49.9 1.3v.4c-1.1 11.5-5 22.9-10.7 30.8-6.7 9-17.2 15.9-28.2 18.4-.3.1-.7.1-1 .1-1.3 0-2.2-.6-2.6-2.1-.1-.3-.1-.7-.1-1 0-1.4.9-2.3 2.3-2.7 10-2.9 19.2-8.4 25-16.4 5.2-7.1 8.5-17.3 9.5-28 .1-1.8 1.4-2.5 2.8-2.5.3 0 .5 0 .7.1 1.3.2 2.3 1.3 2.3 2.9zm-32.1-4.7c1.1 0 2.2.5 2.8 1.7 2.3 5.1 4.9 11.7 6.5 17.5.1.3.1.5.1.8 0 1.4-.9 2.5-2.2 2.8-.3.1-.7.1-1.1.1-1.2 0-2.2-.6-2.6-1.9-1.4-5.5-4.1-12.5-6.3-17.1-.2-.5-.3-1-.3-1.4 0-1.1.7-1.9 1.9-2.3.4-.2.8-.2 1.2-.2zm42.8-1c.9 0 1.8.4 2.4 1.3 7 9.5 10.7 18.4 10.7 31.3 0 12.9-3.7 22-10.6 31.5-.6.9-1.4 1.3-2.3 1.3-.6 0-1.3-.3-1.9-.7-.8-.5-1.4-1.4-1.4-2.3 0-.5.2-1.1.6-1.6 5.9-8.1 9.6-16.3 9.6-28.2 0-11.8-3.6-19.9-9.7-28.1-.4-.5-.6-1.1-.6-1.6 0-.9.5-1.7 1.4-2.3.5-.4 1.2-.6 1.8-.6z" />
    </svg>
  ),
  signature: (
    <svg viewBox="0 0 102 102" fill="currentColor">
      <g transform="translate(1 1)">
        <path d="m28.9 48.7-2.4-2.7-18.9 18.8h-7.5v-7.7l41.9-41.8c3.7-3.2 10-9 27.4-9 5.6 0 11.1 1.2 16.5 3.7 5.4 2.4 10.1 5.5 14.3 9.1l-21.6 21.4h-7.6v8.9c-3 3-6.5 5.2-10.4 6.6-4 1.4-7.9 2-11.7 2-11.7 0.1-18.9-7.9-20-9.3zm54.5-28.3c-2.2-1.1-4.4-1.9-6.6-2.6-2.2-0.6-4.7-1-7.4-1-4.7 0-9.1 1-13.2 2.9s-7.9 4.6-11.3 8l-10.6 10.7 2.6 2.9c1.1 1.3 2.9 2.6 5.6 4.1 2.7 1.4 5.3 2.2 7.8 2.2 1.8 0 3.6-0.3 5.3-0.8s3.3-1.3 4.9-2.4v-14.6h13.5l9.4-9.4z" />
        <path d="m97.7 63.2c-5.9-4.9-10.2 4.3-14.7 7.2-5.5 4.7-11.4 9.3-18.2 12.1 1.1-6.5 2.8-17.7-4.9-17.7-5.6 0-14.9 10.8-20.9 15.8 0.8-6.2-1.4-12.9-8.1-12.9-1.9 0-4.2 1-6.2 3.2-2.8 3.1-7.4 11.7-12 11.8-0.7 0-3.5-0.5-1.8-7.9 1.4-6.3-8-8.9-9.6-2.7-6.2 23.7 16.7 27.5 27.7 9 0.6 24.7 17.5 6.8 26.1-2.1-3.9 19.2 7.1 16.7 19.9 9.7 5.2-3.6 29.9-18.7 22.7-25.5z" />
      </g>
    </svg>
  ),
  'sort-down': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path
        d="M58.1,74.2H45.2l0,0c-1.8,0-3.2,1.4-3.2,3.2l0,0v3.2l0,0
        c0,1.8,1.4,3.2,3.2,3.2h12.9l0,0c1.8,0,3.2-1.4,3.2-3.2v-3.2l0,0C61.3,75.6,59.8,74.2,58.1,74.2L58.1,74.2z M29.8,75.8H21V8.1l0,0
        c0-1.8-1.4-3.2-3.2-3.2h-3.2l0,0c-1.8,0-3.2,1.4-3.2,3.2l0,0v67.7h-9l0,0c-1.3,0-2.4,1.1-2.4,2.4c0,0.6,0.2,1.2,0.7,1.7l13.7,14.5
        l0,0c0.9,1,2.4,1,3.4,0.1l0.1-0.1l13.7-14.5l0,0c0.9-1,0.9-2.5-0.1-3.4C31,76,30.5,75.8,29.8,75.8L29.8,75.8z M83.9,35.5H45.2l0,0
        c-1.8,0-3.2,1.4-3.2,3.2l0,0v3.2l0,0c0,1.8,1.4,3.2,3.2,3.2h38.7l0,0c1.8,0,3.2-1.4,3.2-3.2v-3.2l0,0C87.1,36.9,85.7,35.5,83.9,35.5
        L83.9,35.5z M96.8,16.1H45.2l0,0c-1.8,0-3.2,1.4-3.2,3.2l0,0v3.2l0,0c0,1.8,1.4,3.2,3.2,3.2h51.6l0,0c1.8,0,3.2-1.4,3.2-3.2v-3.2
        l0,0C100,17.6,98.6,16.1,96.8,16.1L96.8,16.1z M71,54.8H45.2l0,0c-1.8,0-3.2,1.4-3.2,3.2l0,0v3.2l0,0c0,1.8,1.4,3.2,3.2,3.2H71l0,0
        c1.8,0,3.2-1.4,3.2-3.2V58l0,0C74.2,56.3,72.7,54.8,71,54.8L71,54.8z"
      />
    </svg>
  ),
  split: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M62.1 50l37.1-37.1c1-1 1-2.7 0-3.8-7.3-7.3-19.2-7.3-26.5 0L46.9 34.9l-5.5-5.5c1-2.4 1.5-5.1 1.5-7.9C42.9 9.6 33.3 0 21.4 0S0 9.6 0 21.4s9.6 21.4 21.4 21.4c1 0 2-.1 3-.2l7.4 7.4-7.4 7.4c-1-.1-2-.2-3-.2C9.6 57.1 0 66.7 0 78.6S9.6 100 21.4 100s21.4-9.6 21.4-21.4c0-2.8-.5-5.4-1.5-7.9l5.5-5.5L72.6 91c7.3 7.3 19.2 7.3 26.5 0 1-1 1-2.7 0-3.8L62.1 50zM21.4 28.6c-3.9 0-7.1-3.2-7.1-7.1s3.2-7.1 7.1-7.1 7.1 3.2 7.1 7.1-3.1 7.1-7.1 7.1zm0 57.1c-3.9 0-7.1-3.2-7.1-7.1 0-3.9 3.2-7.1 7.1-7.1s7.1 3.2 7.1 7.1c.1 3.9-3.1 7.1-7.1 7.1z" />
    </svg>
  ),
  'step-forward': (
    <svg viewBox="0 0 11 16" fill="currentColor">
      <path d="M11 .429V15.57a.422.422 0 0 1-.412.429h-1.65a.422.422 0 0 1-.413-.429v-6.3l-6.72 6.464c-.708.611-1.805.1-1.805-.878V1.143C0 .165 1.097-.347 1.805.265l6.72 6.424V.43c0-.236.186-.429.412-.429h1.65c.227 0 .413.193.413.429Z" />
    </svg>
  ),
  sun: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M49.8 23.8c-14.6 0-26.4 11.7-26.4 26.4s11.7 26.4 26.4 26.4 26.4-11.7 26.4-26.4-11.8-26.4-26.4-26.4zM50 16c-2.2 0-4-1.8-4-4V4c0-2.2 1.8-4 4-4s4 1.8 4 4v8c0 2.2-1.8 4-4 4zm-17 4.6c-1.9 1.1-4.4.4-5.5-1.5l-4-6.9c-1.1-1.9-.4-4.4 1.5-5.5 1.9-1.1 4.4-.4 5.5 1.5l4 6.9c1.1 1.9.4 4.4-1.5 5.5zM20.6 33c-1.1 1.9-3.6 2.6-5.5 1.5l-6.9-4c-1.9-1.1-2.6-3.6-1.5-5.5s3.6-2.6 5.5-1.5l6.9 4c1.9 1.1 2.6 3.6 1.5 5.5zM16 50c0 2.2-1.8 4-4 4H4c-2.2 0-4-1.8-4-4s1.8-4 4-4h8c2.2 0 4 1.8 4 4zm4.6 17c1.1 1.9.4 4.4-1.5 5.5l-6.9 4c-1.9 1.1-4.4.4-5.5-1.5-1.1-1.9-.4-4.4 1.5-5.5l6.9-4c1.9-1.1 4.4-.4 5.5 1.5zM33 79.4c1.9 1.1 2.6 3.6 1.5 5.5l-4 6.9c-1.1 1.9-3.6 2.6-5.5 1.5s-2.6-3.6-1.5-5.5l4-6.9c1.1-1.9 3.6-2.6 5.5-1.5zM50 84c2.2 0 4 1.8 4 4v8c0 2.2-1.8 4-4 4s-4-1.8-4-4v-8c0-2.2 1.8-4 4-4zm17-4.6c1.9-1.1 4.4-.4 5.5 1.5l4 6.9c1.1 1.9.4 4.4-1.5 5.5-1.9 1.1-4.4.4-5.5-1.5l-4-6.9c-1.1-1.9-.4-4.4 1.5-5.5zM79.4 67c1.1-1.9 3.6-2.6 5.5-1.5l6.9 4c1.9 1.1 2.6 3.6 1.5 5.5s-3.6 2.6-5.5 1.5l-6.9-4c-1.9-1.1-2.6-3.6-1.5-5.5zM84 50c0-2.2 1.8-4 4-4h8c2.2 0 4 1.8 4 4s-1.8 4-4 4h-8c-2.2 0-4-1.8-4-4zm-4.6-17c-1.1-1.9-.4-4.4 1.5-5.5l6.9-4c1.9-1.1 4.4-.4 5.5 1.5 1.1 1.9.4 4.4-1.5 5.5l-6.9 4c-1.9 1.1-4.4.4-5.5-1.5zM67 20.6c-1.9-1.1-2.6-3.6-1.5-5.5l4-6.9c1.1-1.9 3.6-2.6 5.5-1.5s2.6 3.6 1.5 5.5l-4 6.9c-1.1 1.9-3.6 2.6-5.5 1.5z" />
    </svg>
  ),
  surround: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M100 25V6.2c0-3.5-2.8-6.2-6.2-6.2H75c-3.5 0-6.2 2.8-6.2 6.2H31.2C31.2 2.8 28.5 0 25 0H6.2C2.8 0 0 2.8 0 6.2V25c0 3.5 2.8 6.2 6.2 6.2v37.5C2.8 68.8 0 71.5 0 75v18.8c0 3.5 2.8 6.2 6.2 6.2H25c3.5 0 6.2-2.8 6.2-6.2h37.5c0 3.5 2.8 6.2 6.2 6.2h18.8c3.5 0 6.2-2.8 6.2-6.2V75c0-3.5-2.8-6.2-6.2-6.2V31.2c3.5 0 6.3-2.7 6.3-6.2zM81.2 12.5h6.2v6.2h-6.2v-6.2zm-68.7 0h6.2v6.2h-6.2v-6.2zm6.3 75h-6.2v-6.2h6.2v6.2zm68.7 0h-6.2v-6.2h6.2v6.2zm-6.3-18.7H75c-3.5 0-6.2 2.8-6.2 6.2v6.2H31.2V75c0-3.5-2.8-6.2-6.2-6.2h-6.2V31.2H25c3.5 0 6.2-2.8 6.2-6.2v-6.2h37.5V25c0 3.5 2.8 6.2 6.2 6.2h6.2v37.6z" />
    </svg>
  ),
  'surround-outline': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path
        d="M89.3,71.9V28.1c6.2-1.6,10.7-7.1,10.7-13.8C100,6.4,93.6,0,85.7,0
	c-6.6,0-12.2,4.6-13.8,10.7H28.1C26.5,4.6,20.9,0,14.3,0C6.4,0,0,6.4,0,14.3c0,6.6,4.6,12.2,10.7,13.8v43.9C4.6,73.5,0,79.1,0,85.7
	C0,93.6,6.4,100,14.3,100c6.6,0,12.2-4.6,13.8-10.7h43.9c1.6,6.2,7.1,10.7,13.8,10.7c7.9,0,14.3-6.4,14.3-14.3
	C100,79.1,95.4,73.5,89.3,71.9z M71.9,82.1H28.1c-1.3-5-5.2-8.9-10.2-10.2V28.1c5-1.3,8.9-5.2,10.2-10.2h43.9
	c1.3,5,5.2,8.9,10.2,10.2v43.9C77.1,73.2,73.2,77.1,71.9,82.1z M85.7,7.1c3.9,0,7.1,3.2,7.1,7.1s-3.2,7.1-7.1,7.1
	c-3.9,0-7.1-3.2-7.1-7.1S81.8,7.1,85.7,7.1z M7.1,14.3c0-3.9,3.2-7.1,7.1-7.1s7.1,3.2,7.1,7.1s-3.2,7.1-7.1,7.1S7.1,18.2,7.1,14.3z
	 M14.3,92.9c-3.9,0-7.1-3.2-7.1-7.1s3.2-7.1,7.1-7.1s7.1,3.2,7.1,7.1S18.2,92.9,14.3,92.9z M85.7,92.9c-3.9,0-7.1-3.2-7.1-7.1
	s3.2-7.1,7.1-7.1c3.9,0,7.1,3.2,7.1,7.1S89.6,92.9,85.7,92.9z"
      />
    </svg>
  ),
  text: (
    <svg viewBox="0 0 102 102" fill="currentColor">
      <g transform="translate(1 1)">
        <path d="M25.4,90.3V23.4H0V9.7h64.5v13.7H39.1v66.9H25.4z M70.6,90.3V49.2H54.8V35.5H100v13.7H84.3v41.2H70.6z" />
      </g>
    </svg>
  ),
  'thumbs-down': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M3.5 2.3h15c2 0 3.5 1.9 3.5 3.8v45.3c0 1.9-1.6 3.8-3.5 3.8h-15c-2 0-3.5-1.9-3.5-3.8V6.1c0-1.9 1.6-3.8 3.5-3.8zM100 57.6v-1.5c0-3.8-3.2-6.8-7-6.8h-2.5c3.6-.3 6.5-3.5 6.5-7.2v-1.5c0-3.8-3.2-7.3-7-7.3h-3c3.9 0 7-2.9 7-6.7v-1.5c0-3.8-3.2-6.8-7-6.8h-3c3.9 0 7-3.4 7-7.2V9.6c0-3.8-3.2-7.3-7-7.3H40.2c-6.1 0-12.1 6.2-12.1 12.3v38c0 3.9 20.5 40 20.5 40 2.1 4.6 7.6 6.7 12.2 4.6l1.2-.5c4.6-2.1 7.3-7.7 6-12.6l-5.4-19.7H93c3.8-.1 7-3 7-6.8z" />
    </svg>
  ),
  'thumbs-up': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M0 94.2V48.8C0 46.9 1.6 45 3.5 45h15c2 0 3.5 1.9 3.5 3.8v45.3c0 1.9-1.6 3.8-3.5 3.8h-15C1.6 98 0 96.1 0 94.2zM93 36H62.7L68 16.3c1.3-4.9-1.4-10.6-6-12.6l-1.2-.5c-4.6-2.1-10.1 0-12.2 4.6 0 0-20.5 36.1-20.5 40v38c0 6.1 5.9 12.3 12.1 12.3h43.7c3.9 0 7-3.4 7-7.3v-1.5c0-3.8-3.2-7.2-7-7.2h3c3.9 0 7-2.9 7-6.8v-1.5c0-3.8-3.2-6.7-7-6.7h3c3.9 0 7-3.4 7-7.3v-1.5c0-3.7-2.9-6.9-6.5-7.2H93c3.9 0 7-2.9 7-6.8v-1.5c0-3.9-3.2-6.8-7-6.8z" />
    </svg>
  ),
  time: (
    <svg viewBox="0 0 50 50" fill="currentColor">
      <path d="m31.5 33.25 1.7-1.7-7.95-8v-10.05h-2.25v10.95zm-7.5 9.75q-3.9 0-7.375-1.5t-6.05-4.075-4.075-6.05-1.5-7.375 1.5-7.375 4.075-6.05 6.05-4.075 7.375-1.5 7.375 1.5 6.05 4.075 4.075 6.05 1.5 7.375-1.5 7.375-4.075 6.05-6.05 4.075-7.375 1.5zm0-19zm0 16.75q6.9 0 11.825-4.925t4.925-11.825-4.925-11.825-11.825-4.925-11.825 4.925-4.925 11.825 4.925 11.825 11.825 4.925z" />
    </svg>
  ),
  trash: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M98 27c0-4.4-3.6-10-8-10H75.8L68.7 5.7C66.5 2.1 62.6 0 58.5 0h-17c-4.2 0-8 2.1-10.3 5.7l-7 11.3H10c-4.4 0-8 5.6-8 10 0 1.1.9 2 2 2h9v63c0 4.4 3.6 8 8 8h58c4.4 0 8-3.6 8-8V29h9c1.1 0 2-.9 2-2zM39.8 11c.4-.6 1.1-1 1.8-1h16.9c.7 0 1.4.4 1.8 1l3.8 6H36l3.8-6zM33 86h-2c-1.7 0-3-1.4-3-3V35c0-1.7 1.4-3 3-3h2c1.7 0 3 1.4 3 3v48c0 1.6-1.4 3-3 3zm18 0h-2c-1.7 0-3-1.4-3-3V35c0-1.7 1.4-3 3-3h2c1.7 0 3 1.4 3 3v48c0 1.6-1.4 3-3 3zm18 0h-2c-1.7 0-3-1.4-3-3V35c0-1.7 1.4-3 3-3h2c1.7 0 3 1.4 3 3v48c0 1.6-1.4 3-3 3z" />
    </svg>
  ),
  unlock: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M79 46.2V29.6C79 13.6 65.9 0 50 0 37.1 0 26.3 8.4 22.5 19.9c-.8 2.3.6 4.8 3 5.3l5.8 1.2c1.9.4 3.9-.6 4.6-2.5C38 18.4 43 14.3 49.1 14c8.8-.4 15.9 6.5 15.9 15v17H23c-6.6 0-12 5.4-12 12v30c0 6.6 5.4 12 12 12h54c6.6 0 12-5.4 12-12V58c0-5.9-4.3-10.9-10-11.8zM50 85c-4.4 0-8-3.6-8-8v-8c0-4.4 3.6-8 8-8s8 3.6 8 8v8c0 4.4-3.6 8-8 8z" />
    </svg>
  ),
  upload: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M56.2 26.6V0h-39c-2.6 0-4.7 2.1-4.7 4.7v90.6c0 2.6 2.1 4.7 4.7 4.7h65.6c2.6 0 4.7-2.1 4.7-4.7V31.2H60.9c-2.5 0-4.7-2.1-4.7-4.6zm15 36.9c2 1.9.6 5.3-2.2 5.3H56.1v15.6c0 1.7-1.4 3.1-3.1 3.1h-6.2c-1.7 0-3.1-1.4-3.1-3.1V68.8H31c-2.8 0-4.2-3.3-2.2-5.3l18.8-18.7c1.3-1.3 3.4-1.3 4.7 0l18.9 18.7zm14.9-43L67 1.4C66.1.5 64.9 0 63.7 0h-1.2v25h25v-1.2c0-1.2-.5-2.4-1.4-3.3z" />
    </svg>
  ),
  url: (
    <svg viewBox="0 0 50 50" fill="currentColor">
      <path d="m22 33.1h-8q-3.85 0-6.475-2.625t-2.625-6.475 2.625-6.475 6.475-2.625h8v2.3h-8q-2.9 0-4.85 1.95t-1.95 4.85 1.95 4.85 4.85 1.95h8zm-5.3-7.95v-2.3h14.65v2.3zm9.3 7.95v-2.3h8q2.9 0 4.85-1.95t1.95-4.85-1.95-4.85-4.85-1.95h-8v-2.3h8q3.85 0 6.475 2.625t2.625 6.475-2.625 6.475-6.475 2.625z" />
    </svg>
  ),
  user: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M50 10c9.9 0 18 8.1 18 18s-8.1 18-18 18-18-8.1-18-18 8.1-18 18-18m0-10C34.5 0 22 12.5 22 28s12.5 28 28 28 28-12.5 28-28S65.5 0 50 0zm0 70c12.6 0 23.4 4.8 31.2 13.8.5.6.8 1.4.8 2.3V90H18v-3.8c0-.9.4-1.9 1-2.6C26.9 74.7 37.6 70 50 70m0-10c-15.6 0-29 6.2-38.5 16.9C9.3 79.5 8 82.7 8 86.2v7.2c0 3.7 3 6.7 6.7 6.7h70.6c3.7 0 6.7-3 6.7-6.7v-7.2c0-3.2-1.1-6.4-3.2-8.9C79.3 66.4 65.8 60 50 60z" />
    </svg>
  ),
  wand: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M87.7 25.3L85 22.6c-1.4-1.4-3.2-2-4.9-2-1.8 0-3.6.7-4.9 2L12.3 85.4c-2.7 2.7-2.7 7.2 0 9.9L15 98c1.4 1.4 3.2 2 4.9 2 1.8 0 3.6-.7 4.9-2l62.8-62.8c2.8-2.8 2.8-7.2.1-9.9zm-3.5 6.3L65.5 50.3 60 44.8l18.7-18.7c.5-.5 1.1-.6 1.4-.6.3 0 .9.1 1.4.6l2.7 2.7c.5.5.6 1.1.6 1.4s-.1.9-.6 1.4zM30.6 7.1l2.8 11.3c.2.6.6 1.1 1.2 1.2l11.3 2.8c1.7.4 1.7 2.9 0 3.3l-11.3 2.8c-.6.2-1.1.6-1.2 1.2L30.6 41c-.4 1.7-2.9 1.7-3.3 0l-2.8-11.3c-.2-.6-.6-1.1-1.2-1.2L12 25.7c-1.7-.4-1.7-2.9 0-3.3l11.3-2.8c.6-.2 1.1-.6 1.2-1.2l2.8-11.3c.4-1.7 2.9-1.7 3.3 0zm29.1-5.7l.9 3.5c.2.7.7 1.2 1.4 1.4l3.5.9c1.9.5 1.9 3.2 0 3.7l-3.5.9c-.7.2-1.2.7-1.4 1.4l-.9 3.5c-.5 1.9-3.2 1.9-3.7 0l-.9-3.5c-.2-.7-.7-1.2-1.4-1.4l-3.5-.9c-1.9-.5-1.9-3.2 0-3.7l3.5-.9c.7-.2 1.2-.7 1.4-1.4l.9-3.5c.4-1.9 3.2-1.9 3.7 0z" />
    </svg>
  ),
  warning: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M83.9 95.3H16.1c-6.8 0-12-2.4-14.5-6.8s-2-10 1.4-16l33.9-58.7C40.3 7.9 45 4.6 50 4.6s9.7 3.3 13.1 9.2L97 72.6c3.4 5.9 3.9 11.6 1.4 16-2.5 4.3-7.7 6.7-14.5 6.7zm-27-19.7c0-3.5-2.9-6.4-6.4-6.4s-6.4 2.9-6.4 6.4c0 3.5 2.9 6.4 6.4 6.4s6.4-2.8 6.4-6.4zm.2-40.4c0-3.6-2.9-6.6-6.6-6.6s-6.5 3-6.5 6.6l1.5 23.3c.2 2.6 2.4 4.6 5 4.6 5 0 5-4.6 5-4.6l1.6-23.3z" />
    </svg>
  ),
  'warning-stroke': (
    <svg viewBox="0 0 100 100">
      <path
        fill="#FFFFFF"
        d="M50,4.7c-6,0-11.6,3.8-15.4,10.4L3.8,68.5C0,75.2-0.5,81.8,2.5,87c3,5.3,8.9,8.2,16.7,8.2h61.7
   c7.6,0,13.7-2.9,16.6-8.1c3-5.3,2.6-11.9-1.3-18.6L65.4,15.1C61.6,8.5,56,4.7,50,4.7L50,4.7z"
      />
      <path
        fill="#FFCA28"
        d="M80.8,91.2H19.2C13,91.2,8.3,89,6,85s-1.8-9.1,1.3-14.6L38.1,17c3.1-5.4,7.4-8.4,11.9-8.4
   s8.8,3,11.9,8.4l30.8,53.5c3.1,5.4,3.5,10.6,1.3,14.6C91.7,89,87,91.2,80.8,91.2z"
      />
      <path
        fill="#000"
        d="M56.3,73.3c0-3.2-2.6-5.8-5.8-5.8s-5.8,2.6-5.8,5.8c0,3.2,2.6,5.8,5.8,5.8S56.3,76.6,56.3,73.3z M56.5,36.5
   c0-3.3-2.6-6-6-6s-5.9,2.7-5.9,6L46,57.7l0,0c0.2,2.4,2.2,4.2,4.5,4.2c4.5,0,4.5-4.2,4.5-4.2L56.5,36.5z"
      />
    </svg>
  ),
  workflows: (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M99 74c0 7.7-6.3 14-14 14s-14-6.3-14-14c0-1.1.1-2.2.4-3.2L50 58.6 28.6 70.8c.2 1 .4 2.1.4 3.2 0 7.7-6.3 14-14 14S1 81.7 1 74s6.3-14 14-14c3.7 0 7.1 1.5 9.6 3.9L46 51.7V27.4c-5.8-1.7-10-7.1-10-13.4 0-7.7 6.3-14 14-14s14 6.3 14 14c0 6.3-4.2 11.7-10 13.4v24.3l21.4 12.2c2.5-2.4 5.9-3.9 9.6-3.9 7.7 0 14 6.3 14 14zM14.1 51.9c.7-11.7 6.9-22 16.2-28-1.5-3-2.3-6.3-2.3-9.9v-.4C13.7 21.4 4 36.6 4 54v.8c3-1.7 6.4-2.7 10.1-2.9zm58-37.9c0 3.6-.8 6.9-2.3 9.9 9.3 6.1 15.5 16.3 16.2 28 3.6.2 7.1 1.2 10 2.9V54c0-17.4-9.7-32.6-23.9-40.4v.4zm-5.6 72C61.6 88.5 56 90 50 90s-11.5-1.4-16.5-4c-1.9 3-4.5 5.4-7.6 7.2 7 4.3 15.3 6.8 24.1 6.8s17.1-2.5 24.1-6.8C71 91.4 68.4 89 66.5 86z" />
    </svg>
  ),
  'x-close': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path d="M60 50l37.9-37.9c2.7-2.7 2.7-7.2 0-10s-7.2-2.7-10 0L50 40 12.1 2.1c-2.7-2.7-7.2-2.7-10 0s-2.7 7.2 0 10L40 50 2.1 87.9c-2.7 2.7-2.7 7.2 0 10s7.2 2.7 10 0L50 60l37.9 37.9c2.7 2.7 7.2 2.7 10 0s2.7-7.2 0-10L60 50z" />
    </svg>
  ),
  'zoom-in': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path
        d="M60.9,38.3V43c0,1.3-1.1,2.3-2.3,2.3H45.3v13.3c0,1.3-1.1,2.3-2.3,2.3h-4.7
	c-1.3,0-2.3-1.1-2.3-2.3V45.3H22.7c-1.3,0-2.3-1.1-2.3-2.3v-4.7c0-1.3,1.1-2.3,2.3-2.3H36V22.7c0-1.3,1.1-2.3,2.3-2.3H43
	c1.3,0,2.3,1.1,2.3,2.3V36h13.3C59.9,35.9,60.9,37,60.9,38.3z M99.3,94.9l-4.4,4.4c-0.9,0.9-2.4,0.9-3.3,0L67.9,75.6
	c-0.4-0.4-0.7-1-0.7-1.7v-2.6c-7.1,6.2-16.4,9.9-26.6,9.9C18.2,81.2,0,63.1,0,40.6S18.2,0,40.6,0s40.6,18.2,40.6,40.6
	c0,10.2-3.7,19.4-9.9,26.6h2.6c0.6,0,1.2,0.3,1.7,0.7l23.7,23.7C100.2,92.5,100.2,94,99.3,94.9L99.3,94.9z M71.9,40.6
	c0-17.3-14-31.2-31.2-31.2S9.4,23.4,9.4,40.6s14,31.2,31.2,31.2S71.9,57.9,71.9,40.6z"
      />
    </svg>
  ),
  'zoom-out': (
    <svg viewBox="0 0 100 100" fill="currentColor">
      <path
        d="M60.9,38.3V43c0,1.3-1.1,2.3-2.3,2.3H22.7c-1.3,0-2.3-1.1-2.3-2.3v-4.7
	c0-1.3,1.1-2.3,2.3-2.3h35.9C59.9,35.9,60.9,37,60.9,38.3z M99.3,94.9l-4.4,4.4c-0.9,0.9-2.4,0.9-3.3,0L67.9,75.6
	c-0.4-0.4-0.7-1-0.7-1.7v-2.6c-7.1,6.2-16.4,9.9-26.6,9.9C18.2,81.2,0,63.1,0,40.6S18.2,0,40.6,0s40.6,18.2,40.6,40.6
	c0,10.2-3.7,19.4-9.9,26.6h2.6c0.6,0,1.2,0.3,1.7,0.7l23.7,23.7C100.2,92.5,100.2,94,99.3,94.9L99.3,94.9z M71.9,40.6
	c0-17.3-14-31.2-31.2-31.2S9.4,23.4,9.4,40.6s14,31.2,31.2,31.2S71.9,57.9,71.9,40.6z"
      />
    </svg>
  ),
};

export { indicons };
