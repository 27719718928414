import styled from 'styled-components';

import { COLORS, TYPOGRAPHY } from 'Permafrost/index';

export const StyledWizard = styled.div`
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 24px;

    // override for some styling in AppChrome IPA component
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  h1 {
    margin-bottom: 0;
  }

  .wizard-buttons {
    display: flex;
    align-items: center;

    font-size: ${TYPOGRAPHY.fontSize.subheadLarge};

    button.link-style {
      font-size: inherit;
      margin-right: 1em;
    }
  }
`;

const commonPadding = '20px';

// todo: permafrost: move into css fragment at top level
export const WizardCard = styled.div`
  width: 100%;
  padding: 14px ${commonPadding};

  background-color: ${COLORS.clay};
  border: 1px solid ${COLORS.oxfordBlue};
  border-radius: 4px;
  box-shadow: 1px 1px 1px 0 ${COLORS.ebony};
`;

export const WizardSection = styled.div`
  .section-heading {
    margin-bottom: 0;
    // todo: store value elsewhere
    font-size: 22px;
    color: ${COLORS.lightFontColor};

    .supporting-text {
      font-size: ${TYPOGRAPHY.fontSize.subheadLarge};
      color: ${COLORS.defaultFontColor};
    }
  }

  .section-content {
    padding: ${commonPadding};
  }

  :not(:last-of-type) {
    margin-bottom: ${commonPadding};

    .section-content {
      border-bottom: 1px solid ${COLORS.ebony};
    }
  }
`;
