import styled, { keyframes } from 'styled-components';

type LiProps = {
  animationDistance: number;
  animationDuration: number;
};

export const StyledListItem = styled.li<LiProps>`
  :active {
    cursor: grabbing;
  }

  &.dragging > * {
    box-shadow: rgba(0, 0, 0, 0.5) 0 3px 8px 0;
  }

  // animation gets weird if common style rules are moved into a shared declaration...
  &.animate-up {
    animation: ${(props) => slideKeyframes(props.animationDistance, 'up')}
      ${(props) => props.animationDuration}ms ease-in;
    animation-fill-mode: forwards;
  }

  &.animate-down {
    animation: ${(props) => slideKeyframes(props.animationDistance, 'down')}
      ${(props) => props.animationDuration}ms ease-in;
    animation-fill-mode: forwards;
  }

  &.animate-up,
  &.animate-down {
    transition: opacity 120ms;

    &.active {
      // ensures clicked item remains visually on top during movement
      z-index: 10000;
    }

    &:not(.active) {
      opacity: 0.8;
    }
  }
`;

const slideKeyframes = (distance: number, direction: 'up' | 'down') => keyframes`
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, ${direction === 'up' ? '-' : ''}${distance}px);
  }
`;
