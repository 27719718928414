import styled from 'styled-components';

import ReactSelect from 'react-select';

import { COLORS } from 'Permafrost/index';

import { basicDefaults } from '../commonStyles';

export const StyledSingleCombobox = styled(ReactSelect)`
  ${basicDefaults};

  // overall component container
  .combobox__control {
    border-radius: 0;

    background-color: transparent;

    border-top: none;
    border-right: none;
    border-bottom: 1px solid ${COLORS.defaultFontColor};
    border-left: none;

    :hover {
      border-color: ${COLORS.lightFontColor};
    }
  }

  .combobox__control--is-disabled {
    :hover {
      border-color: ${COLORS.defaultFontColor};
    }
  }

  // the dropdown list container
  .combobox__menu {
    background-color: ${COLORS.clay};
    border: 1px solid ${COLORS.mediumGray};
  }

  // the dropdown list
  .combobox__menu-list {
    border-radius: inherit;
    padding-top: 0;
    padding-bottom: 0;
  }

  // text input placeholder text
  .combobox__placeholder {
    color: ${COLORS.defaultFontColor};
  }

  // selected item text inside input
  .combobox__single-value {
    color: ${COLORS.lightFontColor};
  }
`;
