import React from 'react';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import { Icon } from 'Permafrost/index';

import { StyledDragDropListItem } from './DragDropList.styles';

type Props = {
  id: string;
  content: string | React.ReactNode;
  dragDisabled?: boolean;
  index: number;
};

export const DragDropListItem = ({ id, content, dragDisabled, index }: Props) => {
  const stringContent = typeof content === 'string';

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={dragDisabled}>
      {(provided, snapshot) => (
        <StyledDragDropListItem
          className={classNames({
            'is-dragging': snapshot.isDragging,
            'drag-disabled': dragDisabled,
          })}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {!dragDisabled ? <Icon className="draggable-icon" name="draggable" size={[20]} /> : null}
          <span className={classNames('draggable-content', { string: stringContent })}>
            {content}
          </span>
        </StyledDragDropListItem>
      )}
    </Draggable>
  );
};
