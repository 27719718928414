import React from 'react';

const Bar = (props) => {
  const {
    className,
    xPos,
    yPos,
    width,
    height,
    id,
    toolTipKey,
    mouseEnterHandler,
    mouseLeaveHandler,
    mouseMoveHandler,
    contextMenuHandler,
  } = props;

  return (
    <rect
      className={`Bar ${className}`}
      fill="currentColor"
      x={xPos}
      y={yPos}
      width={width}
      height={height}
      id={id}
      data-cy={props['data-cy']}
      data-tip={toolTipKey !== null}
      data-for={toolTipKey}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      onMouseMove={mouseMoveHandler}
      onContextMenu={contextMenuHandler}
    />
  );
};

// Bar.propTypes = {
//     className: PropTypes.string,
//     xPos: PropTypes.number,
//     yPos: PropTypes.number,
//     width: PropTypes.number,
//     height: PropTypes.number,
//     toolTipKey: PropTypes.string,
//     mouseEnterHandler: PropTypes.func,
//     mouseLeaveHandler: PropTypes.func,
//     mouseMoveHandler: PropTypes.func,
//     contextMenuHandler: PropTypes.func
// };

Bar.defaultProps = {
  className: '',
  hoverText: '',
  toolTipKey: null,
  mouseEnterHandler: () => null,
  mouseLeaveHandler: () => null,
  mouseMoveHandler: () => null,
  contextMenuHandler: () => null,
};

export default Bar;
