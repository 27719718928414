import React from 'react';

import { v4 as uuid } from 'uuid';

import { Icon } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledSearchField } from './SearchField.styles';

type Props = PermafrostComponent & {
  inputProps?: { [key: string]: string };
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
};

export const SearchField = (props: Props) => {
  const { className, inputProps, onChange, onKeyUp, placeholder, value } = props;

  const id = uuid();

  return (
    <StyledSearchField className={className} data-cy={props['data-cy']} id={props.id}>
      <label htmlFor={id}>
        <Icon name="fa-search" ariaLabel="search" />
      </label>

      <input
        type="search"
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyUp={onKeyUp}
        {...inputProps}
      />
    </StyledSearchField>
  );
};

SearchField.defaultProps = {
  className: '',
};

export default SearchField;
